import BrandsQuery from 'Query/Brands.query';
import DataContainer from 'Util/Request/DataContainer';
import { getCurrentStoreId } from 'Util/Store/Store';

import FeaturedBrands from './FeaturedBrands.component';

/** @namespace Pwa/Component/FeaturedBrands/Container */
export class FeaturedBrandsContainer extends DataContainer {
    state = {
        sliderItems: [],
    };

    componentDidMount() {
        this.getFeaturedBrandsData();
    }

    getFeaturedBrandsData() {
        const storeId = getCurrentStoreId();

        if (storeId) {
            this.fetchData(
                [BrandsQuery.getBrandsQuery({ is_featured: { eq: '1' } }, storeId, 20)],
                ({ mpbrand }) => {
                    this.setState({ sliderItems: mpbrand ? mpbrand.items : [] });
                },
                () => {
                    this.setState({ sliderItems: [] });
                }
            );
        }
    }

    containerProps() {
        const { sliderItems } = this.state;

        return {
            sliderItems,
        };
    }

    render() {
        return <FeaturedBrands {...this.containerProps()} />;
    }
}

export default FeaturedBrandsContainer;
