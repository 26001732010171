import ProductListPage from 'Component/ProductListPage';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const renderPage = (args, callback, instance) => {
    const [props = {}] = args;
    const {
        isInfiniteLoaderEnabled,
        loadPage,
        isLoading,
        isVisible,
        currentPage,
        mix,
        event,
        tilePromoBanner,
        device,
    } = instance.props;
    const newProps = instance._processProps(props);

    return (
        <ProductListPage
            event={event}
            key={currentPage}
            isInfiniteLoaderEnabled={isInfiniteLoaderEnabled}
            updatePages={loadPage}
            isLoading={isLoading}
            isVisible={isVisible}
            mix={mix}
            tilePromoBanner={tilePromoBanner}
            device={device}
            {...newProps}
        />
    );
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const containerProps = (args, callback, instance) => {
    const { event } = instance.props;

    return {
        ...callback(...args),
        event,
    };
};

export default {
    'Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps,
        },
    },
    'Component/ProductList/Component': {
        'member-function': {
            renderPage,
        },
    },
};
