/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class ConfigQueryPlugin {
    /**
     * Get blog posts per page into config query
     * @returns [Field]
     */
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'mfblog_post_list_posts_per_page',
    ];
}

export const { _aroundGetStoreConfigFields } = new ConfigQueryPlugin();

export const config = {
    'Pwa/Query/Config/Query/ConfigQuery': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields,
        },
    },
};

export default config;
