import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import './Tabs.style';

/** @namespace Pwa/Component/Tabs/Component */
export class TabsComponent extends PureComponent {
    static propTypes = {
        tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
        children: ChildrenType.isRequired,
        activeTab: PropTypes.number.isRequired,
        handleTabClick: PropTypes.func.isRequired,
    };

    renderChildren() {
        const { children } = this.props;

        return children;
    }

    renderTabs() {
        const { tabs } = this.props;
        const { activeTab, handleTabClick } = this.props;

        return tabs.map(({ name, id }) => (
            <button
                key={id}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handleTabClick(id)}
                block="Tabs"
                elem="Tab"
                mods={{ isActive: activeTab === id }}
                mix={{ block: 'TypographyHeader', mods: { heading: 'h5' } }}
            >
                <h2>{name}</h2>
            </button>
        ));
    }

    render() {
        return (
            <div block="Tabs">
                <div className="Tabs-Tabs">{this.renderTabs()}</div>
                {this.renderChildren()}
            </div>
        );
    }
}

export default TabsComponent;
