import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutCeneoOpineoQuery from 'Query/CheckoutCeneoOpineo.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';

import CheckoutCeneoOpineo from './CheckoutCeneoOpineo.component';

/** @namespace Pwa/Component/CheckoutCeneoOpineo/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/CheckoutCeneoOpineo/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/CheckoutCeneoOpineo/Container */
export class CheckoutCeneoOpineoContainer extends PureComponent {
    state = {
        ceneoOpinieEnabled: false,
        ceneoOpinieAccepted: false,
        opineoEnabled: false,
        opineoAccepted: false,
        showMoreInfo: false,
    };

    containerFunctions = {
        setAcceptedState: this.setAcceptedState.bind(this),
        setOpineoOnCart: this.setOpineoOnCart.bind(this),
        setCeneoOpinieOnCart: this.setCeneoOpinieOnCart.bind(this),
        switchDescriptionVisibility: this.switchDescriptionVisibility.bind(this),
    };

    componentDidMount() {
        this.getCeneoOpineoConfig();
    }

    switchDescriptionVisibility(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState(({ showMoreInfo: prevShowMoreInfo }) => ({
            showMoreInfo: !prevShowMoreInfo,
        }));
    }

    setAcceptedState(item) {
        this.setState(({ [item]: oldItem }) => ({
            [item]: !oldItem,
        }));
    }

    getCeneoOpineoConfig() {
        fetchQuery(CheckoutCeneoOpineoQuery.getCeneoOpineoConfigData()).then(
            /** @namespace Pwa/Component/CheckoutCeneoOpineo/Container/CheckoutCeneoOpineoContainer/getCeneoOpineoConfig/fetchQuery/then */
            ({
                storeConfig: {
                    opineo_wiarygodneopinie_options_opineo_enabled,
                    ceneo_opinie_enabled,
                    ceneo_opinie_agreement,
                },
            }) => {
                this.setState({
                    opineoEnabled: opineo_wiarygodneopinie_options_opineo_enabled,
                    ceneoOpinieEnabled: ceneo_opinie_enabled && ceneo_opinie_agreement,
                });
            }
        );
    }

    async setOpineoOnCart() {
        this.setAcceptedState('opineoAccepted');

        const guestCartId = !isSignedIn() ? getGuestQuoteId() : '';
        const { opineoAccepted } = this.state;

        if (isSignedIn()) {
            await fetchMutation(
                CheckoutCeneoOpineoQuery.setCustomerOrderOpineoAgreementMutation(guestCartId, !opineoAccepted)
            );
        } else {
            await fetchMutation(
                CheckoutCeneoOpineoQuery.setGuestOrderOpineoAgreementMutation(guestCartId, {
                    opineo_agreement: !+opineoAccepted,
                })
            );
        }
    }

    async setCeneoOpinieOnCart() {
        this.setAcceptedState('ceneoOpinieAccepted');

        const guestCartId = !isSignedIn() ? getGuestQuoteId() : '';
        const { ceneoOpinieAccepted } = this.state;

        await fetchMutation(CheckoutCeneoOpineoQuery.setCeneoOpinieMutation(guestCartId, !ceneoOpinieAccepted));
    }

    containerProps() {
        const {
            ceneoOpinieEnabled,
            opineoEnabled,
            ceneoOpinieAccepted,
            opineoAccepted,
            setAcceptedState,
            showMoreInfo,
        } = this.state;

        return {
            ceneoOpinieEnabled,
            opineoEnabled,
            setAcceptedState,
            ceneoOpinieAccepted,
            opineoAccepted,
            showMoreInfo,
        };
    }

    render() {
        return <CheckoutCeneoOpineo {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCeneoOpineoContainer);
