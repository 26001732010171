import Field from 'Component/Field';
import { KeyValueTable as SourceKeyValueTable } from 'SourceComponent/KeyValueTable/KeyValueTable.component';

import './KeyValueTable.style';

/** @namespace Pwa/Component/KeyValueTable/Component */
export class KeyValueTable extends SourceKeyValueTable {
    getValueFromSource({ key, source }) {
        const { [key]: value } = source;

        return Array.isArray(value) ? value.join(' ') : value;
    }

    renderHeading() {
        const { title, isSelected } = this.props;

        if (!title) {
            return null;
        }

        return (
            <tr>
                <th block="KeyValueTable" elem="Heading" colSpan={2}>
                    <Field type="radio" attr={{ checked: isSelected }} />
                    {title}
                </th>
            </tr>
        );
    }

    renderTableRow(data) {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <tr key={key}>
                <th>{`${label}:`}</th>
                <td>{value}</td>
            </tr>
        );
    }
}

export default KeyValueTable;
