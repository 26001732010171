import myAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.form';
import TypographyHeader from 'Component/TypographyHeader';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutAddressForm as SourceCheckoutAddressForm } from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component';

import './CheckoutAddressForm.style';

/** @namespace Pwa/Component/CheckoutAddressForm/Component */
export class CheckoutAddressForm extends SourceCheckoutAddressForm {
    get fieldMap() {
        const {
            id,
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
        } = this.props;

        const fieldMap = myAccountAddressForm(
            {
                address,
                countries,
                addressLinesQty,
                regionDisplayAll,
                showVatNumber,
                defaultCountry,
                availableRegions,
                isStateRequired,
                countryId,
                currentRegion,
                currentCity,
                currentRegionId,
                currentZipcode,
                ...address,
            },
            {
                onCountryChange,
                onZipcodeChange,
                onCityChange,
                onRegionChange,
                onRegionIdChange,
            },
            id === SHIPPING_STEP
        );

        const addressGroup = fieldMap.find(({ name }) => name === 'addressGroup');

        if (addressGroup) {
            addressGroup.events = {
                // Updates shipping methods on address blurt
                onBlur: this.onAddressChange,
                // Updates shipping methods on start
                onLoad: this.onAddressChange,
            };
        }

        fieldMap.splice(0, 2);

        if (id === BILLING_STEP) {
            const updatedFieldMap = fieldMap.map((item) => {
                const { attr: { name } = {} } = item;
                if (name === 'firstname' || name === 'lastname') {
                    item.validationRule = {};
                    item.addRequiredTag = false;
                }

                return item;
            });

            return updatedFieldMap;
        }

        return fieldMap;
    }

    renderHeading() {
        const { isBilling } = this.props;

        if (isBilling) {
            return null;
        }

        return (
            <TypographyHeader variant="medium" fontWeight="medium">
                {__('Delivery address')}
            </TypographyHeader>
        );
    }

    render() {
        return <div block="CheckoutAddressForm">{this.renderFormBody()}</div>;
    }
}

export default CheckoutAddressForm;
