import { FIELD_RADIO_NONE, FIELD_TYPE as SOURCE_FIELD_TYPE } from 'SourceComponent/Field/Field.config';

export { FIELD_RADIO_NONE };

export const FIELD_TYPE = {
    ...SOURCE_FIELD_TYPE,
    tel: 'tel',
};

export default FIELD_TYPE;
