import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace Pwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        variant: PropTypes.string,
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        variant: 'primary',
    };

    containerProps() {
        const { variant, size } = this.props;

        return {
            ...super.containerProps(),
            variant,
            size,
        };
    }
}

export default FieldContainer;
