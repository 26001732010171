import { ExtraFeeDispatcher } from '@mirat/extrafee/src/component/ExtraFee/ExtraFee.container';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateExtraFee: (customerId) =>
            ExtraFeeDispatcher.then(({ default: dispatcher }) => dispatcher.prepareRequest(customerId, dispatch)),
        reloadCartData: () =>
            CartDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)),
    };
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
};
