import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumber as SourceFieldNumber } from 'SourceComponent/FieldNumber/FieldNumber.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

/** @namespace Pwa/Component/FieldNumber/Component */
export class FieldNumber extends SourceFieldNumber {
    static propTypes = {
        ...SourceFieldNumber.propTypes,
        value: PropTypes.number,
    };

    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <input
                    ref={(elem) => setRef(elem)}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...attr}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...events}
                    value={value}
                    type={FIELD_TYPE.number}
                    aria-label={__('Value')}
                    disabled={isDisabled}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (value) {
                            handleValueChange(parseInt(value, 10));
                        }
                    }}
                />
                <button
                    disabled={max === 1 || numberValue >= max || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(numberValue + 1)}
                    aria-label={__('Add')}
                    type={FIELD_TYPE.button}
                >
                    <AddIcon block="SubtractButton" />
                </button>
                <button
                    disabled={numberValue + 1 === min || numberValue <= min || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(numberValue - 1)}
                    aria-label={__('Subtract')}
                    type={FIELD_TYPE.button}
                >
                    <MinusIcon block="AddButton" />
                </button>
            </>
        );
    }
}

export default FieldNumber;
