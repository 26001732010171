import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

export class CheckoutContainerPlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        savePaymentInformationOnly: this.savePaymentInformationOnly.bind(instance),
    });

    async savePaymentInformationOnly(paymentCode) {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        const { updateExtraFee, reloadCartData, customer: { id: customerId } = {} } = this.props;

        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            await fetchMutation(
                CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    guest_cart_id,
                    payment_method: {
                        code: paymentCode,
                    },
                })
            )
                .then(() => {
                    updateExtraFee(customerId);
                    reloadCartData();
                })
                .finally(
                    /** @namespace Pwa/Route/Checkout/Container/fetchMutation/then */
                    () => this.setState({ isLoading: false })
                );
        } catch (e) {
            this._handleError(e);
        }
    }
}
const { containerFunctions } = new CheckoutContainerPlugin();

export default {
    'Pwa/Route/Checkout/Container': {
        'member-function': {
            containerFunctions
        }
    }
};
