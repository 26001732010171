import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Slider/Query */
export class Slider {
    getSliderQuery(position, code) {
        const query = new Field('slider')
            .addArgument('position', 'String!', position)
            .addArgument('code', 'String!', code)
            .addField(this._getSliderItemField());

        return query;
    }

    _getSliderItemField() {
        return new Field('slides').addFieldList([
            'body',
            'cta_color',
            'cta_link',
            'cta_text',
            'header',
            'image',
            'image_height',
            'image_width',
            'mobile_image',
            'tablet_image',
        ]);
    }
}

export default new Slider();
