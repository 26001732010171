import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @namespace Pwa/Component/TypographyHeader/Component */
export class TypographyHeaderComponent extends PureComponent {
    static propTypes = {
        tag: PropTypes.string,
        variant: PropTypes.oneOf(['extra_large', 'large', 'medium', 'normal', 'small', 'extra_small']),
        fontWeight: PropTypes.oneOf([
            'thin',
            'extra_light',
            'light',
            'regular',
            'medium',
            'semi_bold',
            'bold',
            'extra_bold',
            'black',
        ]),
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    };

    static defaultProps = {
        tag: 'h1',
        variant: 'large',
        fontWeight: 'regular',
    };

    render() {
        const { tag, variant, fontWeight, children, mix, ...rest } = this.props;
        const HeadingTag = tag;

        return (
            <HeadingTag block="TypographyHeader" mods={{ variant, fontWeight }} mix={mix} {...rest}>
                {children}
            </HeadingTag>
        );
    }
}

export default TypographyHeaderComponent;
