import PropTypes from 'prop-types';

import Icons from 'Component/Icons';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

/** @namespace Pwa/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...SourceExpandableContent.propTypes,
        isExpandableOnDesktop: PropTypes.bool,
        iconVariant: PropTypes.oneOf(['primary', 'secondary']),
    };

    static defaultProps = {
        ...SourceExpandableContent.defaultProps,
        isExpandableOnDesktop: false,
        iconVariant: 'primary',
    };

    static getDerivedStateFromProps(
        { isContentExpanded, isExpandableOnDesktop, device: { isMobile, isTablet } },
        { prevIsContentExpanded }
    ) {
        const isExpandedOnStart = (!isMobile && !isTablet && isExpandableOnDesktop) || isContentExpanded;
        if (isExpandedOnStart !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isExpandedOnStart,
                isContentExpanded: isExpandedOnStart,
            };
        }

        return null;
    }

    renderIconMap = {
        primary: {
            up: () => <Icons name="arrow_up" fill="#8A8989" width="10px" />,
            down: () => <Icons name="arrow_down" fill="#8A8989" width="10px" />,
        },
        secondary: {
            up: () => <Icons name="dropdown_arrow" fill="#000" width="20px" />,
            down: () => <Icons name="dropdown_arrow_up" fill="#000" width="20px" />,
        },
    };

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;
        const { iconVariant } = this.props;

        if (isContentExpanded) {
            return this.renderIconMap[iconVariant].up();
        }

        return this.renderIconMap[iconVariant].down();
    }

    renderButtonIcon() {
        const {
            isExpandableOnDesktop,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet && !isExpandableOnDesktop) {
            return null;
        }

        return this.renderTogglePlusMinus();
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix, iconVariant } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded, iconVariant }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
            >
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
                </div>
                {this.renderButtonIcon()}
            </div>
        );
    }

    renderContent() {
        const { children, mix, isExpandableOnDesktop } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, isExpandableOnDesktop };

        return (
            <div
                block="ExpandableContent"
                elem="Content"
                mods={mods}
                mix={{ ...mix, elem: 'ExpandableContentContent', mods }}
            >
                {children}
            </div>
        );
    }
}

export default ExpandableContent;
