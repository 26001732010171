import { ProductListQuery } from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CatalogWidget/Query */
export class CatalogWidget extends ProductListQuery {
    getCatalogWidgetQuery(widgetCode, options = {}) {
        this.options = options;

        const query = new Field('catalogWidget')
            .addArgument('widget_code', 'String!', widgetCode)
            .addFieldList(this._getCatalogWidgetFields());

        return query;
    }

    _getCatalogWidgetFields() {
        return ['title', 'widget_code', this._getItemsField()];
    }

    _getPriceExcludingTaxField() {
        return new Field('price_excluding_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getPriceIncludingTaxField() {
        return new Field('price_including_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getFinalPriceField() {
        return new Field('final_price').addFieldList(['currency', 'value']);
    }

    _getRegularPriceField() {
        return new Field('regular_price').addFieldList(['currency', 'value']);
    }

    _getItemsField() {
        return new Field('items').addFieldList([
            'sku',
            'name',
            'type_id',
            'url_key',
            'calculated_delivery_time',
            'entity_id',
            'image',
            'alternative_hover_image',
            this._getLabelField(),
            this._getPriceRangeField(),
            this._getTierPricesField(),
            this._getVariantsFieldX(),
            this._getRelatedProductsByAttribute(),
        ]);
    }

    _getVariantsFieldX() {
        return new Field('variants').setAlias('variants').addFieldList(this._getVariantFields());
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const { isPlp = false, noAttributes = false, noVariants = false, noVariantAttributes = false } = this.options;

        const fields = ['uid', 'id', 'sku', 'name', 'type_id', this._getPriceRangeField()];

        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        return fields;
    }

    _getMinimalPriceFields() {
        return [this._getDiscountField(), this._getFinalPriceField(), this._getRegularPriceField()];
    }

    _getTierPricesFields() {
        return [this._getDiscountField(), this._getFinalPriceField()];
    }
}

export default new CatalogWidget();
