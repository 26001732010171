import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component/build';

import {
    LAZY_BRANDS_SLIDER,
    LAZY_BRANDS_SLIDER_TRESHOLD,
    LAZY_PRODUCT_TILE,
    LAZY_PRODUCT_TILE_RELATED,
    LAZY_PRODUCT_TILE_RELATED_TRESHOLD,
    LAZY_PRODUCT_TILE_TRESHOLD,
} from 'Component/ImageLazyLoading/ImageLazyLoading.config';

import placeholderTile from '../../assets/images/productPlaceholder.png';
import placeholderSmallRelated from '../../assets/images/productPlaceholderSmallRelated.png';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImageLazyLoading.style';

/** @namespace Pwa/Component/ImageLazyLoading/Component */
export class ImageLazyLoading extends PureComponent {
    static propTypes = {
        lazyLoadingType: PropTypes.oneOf(['productTile', 'productTileRelated', 'brandsSlider']),
    };

    renderMap = {
        [LAZY_PRODUCT_TILE]: this.renderProductTileLazy.bind(this),
        [LAZY_PRODUCT_TILE_RELATED]: this.renderProductTileRelatedLazy.bind(this),
        [LAZY_BRANDS_SLIDER]: this.renderBrandsSliderLazy.bind(this),
    };

    renderProductTileLazy() {
        const { lazyLoadingType, ...rest } = this.props;

        return (
            <LazyLoadImage
                treshold={LAZY_PRODUCT_TILE_TRESHOLD}
                effect="opacity"
                {...rest}
                lazyloadingtype={lazyLoadingType}
                placeholderSrc={<img src={placeholderTile} alt="preloader" />}
            />
        );
    }

    renderProductTileRelatedLazy() {
        const { lazyLoadingType, ...rest } = this.props;

        return (
            <LazyLoadImage
                treshold={LAZY_PRODUCT_TILE_RELATED_TRESHOLD}
                effect="opacity"
                {...rest}
                lazyloadingtype={lazyLoadingType}
                placeholderSrc={<img src={placeholderSmallRelated} alt="preloader" />}
            />
        );
    }

    renderBrandsSliderLazy() {
        const { lazyLoadingType, ...rest } = this.props;

        return (
            <LazyLoadImage
                treshold={LAZY_BRANDS_SLIDER_TRESHOLD}
                effect="opacity"
                {...rest}
                lazyloadingtype={lazyLoadingType}
            />
        );
    }

    render() {
        const { lazyLoadingType } = this.props;
        const render = this.renderMap[lazyLoadingType];

        return render ? render() : null;
    }
}

export default ImageLazyLoading;
