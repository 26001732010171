import BlogRecentPosts from '@mirat/blog/src/component/BlogRecentPosts';
import domToReact from 'html-react-parser/lib/dom-to-react';
import LazyLoad from 'react-lazyload';

import Accordion from 'Component/Accordion';
import BannerSlider from 'Component/BannerSlider';
import CatalogWidget from 'Component/CatalogWidget';
import FeaturedBrands from 'Component/FeaturedBrands';
import PromoBannerAnalytics from 'Component/PromoBannerAnalytics';
import Tabs from 'Component/Tabs';
import { Html as SourceHtml, WidgetFactory } from 'SourceComponent/Html/Html.component';
import isMobile from 'Util/Mobile';

export { WidgetFactory };

/** @namespace Pwa/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        {
            query: { attribs: ['onclick'] },
            replace: this.replaceOnClickWindowLocation,
        },
        {
            query: { attribs: ['data-banner'] },
            replace: this.replaceBanner,
        },
        {
            query: { attribs: ['data-slider'] },
            replace: this.replaceSlider,
        },
        {
            query: { attribs: ['data-recent-posts'] },
            replace: this.replaceRecentPosts,
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.replaceAccordionTabs,
        },
        {
            query: { attribs: ['data-catalog-widget'] },
            replace: this.replaceCatalogWidget,
        },
        {
            query: { attribs: ['data-tabs'] },
            replace: this.replaceTabs,
        },
        {
            query: { attribs: ['data-featured-brands'] },
            replace: this.replaceFeaturedBrands,
        },
        ...this.rules,
    ];

    replaceOnClickWindowLocation({ attribs, children }) {
        const { onclick } = this.attributesToProps(attribs);

        if (onclick.indexOf('window.location.href') > -1) {
            const extractedUrl = onclick.replace('window.location.href=', '').replace(new RegExp('[\'"]', 'g'), '');

            return (
                <div
                    onClick={() => {
                        window.location.href = extractedUrl;
                    }}
                >
                    {domToReact(children, this.parserOptions)}
                </div>
            );
        }

        return false;
    }

    replaceSlider({ attribs }) {
        const { 'data-position': position, 'data-code': code } = this.attributesToProps(attribs);

        return <BannerSlider position={position} code={code} />;
    }

    replaceRecentPosts() {
        return <BlogRecentPosts />;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceAccordionTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        if (attribs['data-device'] === 'mobileAndTablet' && !isMobile.any() && !isMobile.tablet()) {
            return null;
        }

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <LazyLoad once offset={200}>
                <Accordion {...attributes}>{domToReact(children, this.parserOptions)}</Accordion>
            </LazyLoad>
        );
    }

    replaceCatalogWidget({ attribs }) {
        const { 'data-catalog-widget': catalogWidget } = this.attributesToProps(attribs);
        const { pageType } = this.props;

        return (
            <LazyLoad once offset={100}>
                <CatalogWidget code={catalogWidget} pageType={pageType} />
            </LazyLoad>
        );
    }

    replaceTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        return (
            <LazyLoad once offset={100}>
                <Tabs {...attributes}>{domToReact(children, this.parserOptions)}</Tabs>
            </LazyLoad>
        );
    }

    replaceBanner({ attribs, children }) {
        const { 'data-banner': bannerId } = this.attributesToProps(attribs);

        return (
            <PromoBannerAnalytics bannerId={bannerId}>{domToReact(children, this.parserOptions)}</PromoBannerAnalytics>
        );
    }

    replaceFeaturedBrands() {
        return <FeaturedBrands />;
    }
}

export default Html;
