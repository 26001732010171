export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);
    const isVariant = args[0] === undefined ? false : args[0];

    if (!isVariant) {
        fields.push(instance._getAttributesField());
    }

    fields.push(instance._getCategoriesField(), instance._getPriceRangeField());

    return fields;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: aroundGetProductInterfaceFields,
        },
    },
};
