import Icons from 'Component/Icons';
import { Pagination as SourcePagination } from 'SourceComponent/Pagination/Pagination.component';

/** @namespace Pwa/Component/Pagination/Component */
export class Pagination extends SourcePagination {
    renderPageIcon(isNext = false) {
        return isNext ? <Icons name="arrow_right" width="20" /> : <Icons name="arrow_left" width="20" />;
    }

    render() {
        const { isLoading, totalPages, id } = this.props;

        if (totalPages === 1) {
            // do not show pagination, if there are less then one page
            return <ul block="Pagination" />;
        }

        if (isLoading) {
            return this.renderPlaceholder();
        }

        return (
            <nav block="Pagination" aria-label={__('Product list navigation')}>
                <ul id={id} block="Pagination" elem="List">
                    {this.renderPreviousPageLink()}
                    {this.renderFirstPageLink()}
                    {this.renderPreviousJump()}
                    {this.renderPageLinks()}
                    {this.renderNextJump()}
                    {this.renderLastPageLink()}
                    {this.renderNextPageLink()}
                </ul>
            </nav>
        );
    }
}

export default Pagination;
