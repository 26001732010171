import { Suspense } from 'react';

import CheckoutBilling from 'Component/CheckoutBilling';
import Loader from 'Component/Loader';

const renderBillingStep = (args, callback, instance) => {
    const {
        setLoading,
        setDetailsStep,
        shippingAddress,
        paymentMethods = [],
        savePaymentInformation,
        selectedShippingMethod,
        setNewsletterAccepted,
        newsletterSubscription,
        savePaymentInformationOnly,
    } = instance.props;

    return (
        <Suspense fallback={<Loader />}>
            <CheckoutBilling
              setLoading={setLoading}
              paymentMethods={paymentMethods}
              setDetailsStep={setDetailsStep}
              shippingAddress={shippingAddress}
              savePaymentInformationOnly={savePaymentInformationOnly}
              savePaymentInformation={savePaymentInformation}
              selectedShippingMethod={selectedShippingMethod}
              setNewsletterAccepted={setNewsletterAccepted}
              newsletterSubscription={newsletterSubscription}
              renderDiscountCode={instance.renderDiscountCode.bind(instance)}
            />
        </Suspense>
    );
};

export default {
    'Pwa/Route/Checkout/Component': {
        'member-function': {
            renderBillingStep
        }
    }
};
