import { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import ProductCardBanner from 'Component/ProductCardBanner';
import {
    PROMO_BANNER_GRID_POSITION,
    PROMO_BANNER_LIST_POSITION,
} from 'Component/ProductListPage/ProductListPage.config';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

/** @namespace Pwa/Component/ProductListPage/Component */
export class ProductListPage extends SourceProductListPage {
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: { mods: { layout = GRID_LAYOUT } = {} },
            tilePromoBanner,
            device,
        } = this.props;

        return items.map((product, i) => {
            const position = layout === GRID_LAYOUT ? PROMO_BANNER_GRID_POSITION : PROMO_BANNER_LIST_POSITION;
            const shouldRenderInCurrentPosition =
                (items.length > position && i === position) || (items.length <= position && items.length === i + 1);
            const renderPromoBanner =
                shouldRenderInCurrentPosition && tilePromoBanner && !device.isTablet && !device.isMobile;

            return (
                <Fragment key={i}>
                    {renderPromoBanner ? (
                        <ProductCardBanner banner={tilePromoBanner} key={`banner_${i}`} layout={layout} />
                    ) : null}
                    <ProductCard
                        product={product}
                        // eslint-disable-next-line react/no-array-index-key

                        selectedFilters={selectedFilters}
                        layout={layout}
                        {...this.containerProps()}
                    />
                </Fragment>
            );
        });
    }
}

export default withRouter(ProductListPage);
