import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import { DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS } from 'Component/CatalogWidget/CatalogWidget.config';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import { ProductType } from 'Type/ProductList.type';

import './CatalogWidget.style';

/** @namespace Pwa/Component/CatalogWidget/Component */
export class CatalogWidget extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        items: PropTypes.arrayOf(ProductType),
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
    };

    renderProductCard(item, i) {
        return (
            <div block="CatalogWidget" elem="Item" key={i}>
                <ProductCard product={item} sliderMode />
            </div>
        );
    }

    render() {
        const { isLoading, items, settings } = this.props;

        if (isLoading) {
            return (
                <div block="CatalogWidget" elem="Loader">
                    <Loader />
                </div>
            );
        }

        if (!items || !items.length) {
            return (
                <div block="CatalogWidget" elem="NoProducts">
                    {__('No products')}
                </div>
            );
        }

        return (
            <div block="CatalogWidget">
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS}
                    {...settings}
                >
                    {items.map(this.renderProductCard)}
                </SlickSlider>
            </div>
        );
    }
}

export default CatalogWidget;
