import { MAX_CATEGORIES } from '@mirat/blog/src/component/BlogCategories/BlogCategories.config';
import React from 'react';

import TypographyHeader from 'Component/TypographyHeader';

import '../component/BlogCategories/BlogCategories.style.scss';

export default {
    'Mirat/Blog/Component/BlogCategories/Component/BlogCategoriesComponent': {
        'member-function': {
            render(args, callback, instance) {
                const { device, overlayHandler, isCategoryOverlayOpen } = instance.props;

                return (
                    <>
                        <div block="BlogCategories" elem="Wrapper" onClick={ overlayHandler } aria-hidden="true">
                            <TypographyHeader tag="h4" variant="medium">
                                { __('Categories') }
                            </TypographyHeader>
                        </div>
                        { device.isMobile ? (
                            <div block="BlogCategories" elem="Overlay" mods={ { isOpen: isCategoryOverlayOpen } }>
                                { instance.renderContent() }
                            </div>
                        ) : (
                            instance.renderContent()
                        ) }
                    </>
                );
            },
            renderContent(args, callback, instance) {
                const { categories, isLoaded } = instance.props;
                const category = {};

                if (!isLoaded) {
                    return Array(MAX_CATEGORIES)
                        .fill()
                        .map((_, i) => instance.renderCategory(category, i));
                }

                if (isLoaded && categories.length === 0) {
                    return __('No categories');
                }

                return categories.map((category, key) => instance.renderCategory(category, key));
            }
        }
    }
};
