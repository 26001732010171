import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
    __construct(props) {
        super.__construct(props);

        const { paymentMethods } = props;
        const [{ code } = {}] = paymentMethods;
        this.state = {
            selectedPaymentCode: code,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
