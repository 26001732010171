import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CheckoutCeneoOpineo/Query */
export class CheckoutCeneoOpineo {
    getCeneoOpineoConfigData() {
        return new Field('storeConfig').addFieldList(this._getStoreConfigFields());
    }

    _getStoreConfigFields() {
        return [
            'ceneo_opinie_guid',
            'ceneo_opinie_enabled',
            'ceneo_opinie_agreement',
            'opineo_wiarygodneopinie_options_opineo_enabled',
        ];
    }

    setCustomerOrderOpineoAgreementMutation(quote_id, agreement) {
        const mutation = new Field('setCustomerOrderOpineoAgreement')
            .addArgument('quote_id', 'Int!', quote_id)
            .addArgument('order_opineo_agreement', 'OrderOpineoAgreementInput', { opineo_agreement: agreement });

        return mutation;
    }

    setGuestOrderOpineoAgreementMutation(quote_id, agreement) {
        const mutation = new Field('setGuestOrderOpineoAgreement')
            .addArgument('quote_id', 'String!', quote_id)
            .addArgument('order_opineo_agreement', 'OrderOpineoAgreementInput', agreement)
            .addFieldList(this._getOpineoMutationFields());

        return mutation;
    }

    _getOpineoMutationFields() {
        return ['message', 'success'];
    }

    getCeneoOpinieQuery(order_id) {
        return new Field('getCeneoOpinie')
            .addArgument('orderIncrementId', 'String!', order_id)
            .addFieldList(this._getCeneoOpinieField());
    }

    _getCeneoOpinieField() {
        return [
            'show_script',
            'customer_email',
            'order_increment_id',
            'shop_product_ids',
            'work_days_to_send_questionnaire',
            'script_url',
        ];
    }

    setQuoteAgreementMutation(cartId, agreement) {
        const mutation = new Field('setCustomerOrderOpineoAgreement')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('agreement', 'Boolean!', agreement);

        return mutation;
    }

    setCeneoOpinieMutation(cartId, agreement) {
        const mutation = new Field('setQuoteAgreement')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('agreement', 'Boolean!', agreement)
            .addField(this._getAgreementField());

        return mutation;
    }

    _getAgreementField() {
        return new Field('agreement');
    }
}

export default new CheckoutCeneoOpineo();
