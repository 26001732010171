import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        sliderMode: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceProductCardContainer.defaultProps,
        sliderMode: false,
    };

    containerProps() {
        const { sliderMode } = this.props;

        return {
            ...super.containerProps(),
            sliderMode,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
