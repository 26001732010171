import ProductCustomizableOptions from 'Component/ProductCustomizableOptions';
import { Product as SourceProduct } from 'SourceComponent/Product/Product.component';

/** @namespace Pwa/Component/Product/Component */
export class Product extends SourceProduct {
    renderCustomizableOptions() {
        const {
            product: { options },
            updateSelectedValues,
        } = this.props;

        return options ? (
            <ProductCustomizableOptions updateSelectedValues={updateSelectedValues} options={options} />
        ) : null;
    }
}

export default Product;
