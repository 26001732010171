import { MAX_RECENT_POSTS } from '@mirat/blog/src/component/BlogRecentPosts/BlogRecentPosts.config';
import { getPostUrl } from '@mirat/blog/src/util/Posts';
import React from 'react';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import TypographyHeader from 'Component/TypographyHeader';

import '../component/BlogRecentPosts/BlogRecentPosts.style.scss';

export default {
    'Mirat/Blog/Component/BlogRecentPosts/Component/BlogRecentPostsComponent': {
        'member-function': {
            renderCard([post, key]) {
                const { title, first_image } = post || {};
                const postUrl = getPostUrl(post);

                return (
                    <div block="BlogRecentPosts" elem="Post" mods={ { first: key === 0, rest: key !== 0 } } key={ key }>
                        <Link to={postUrl} title={title} mix={{ block: 'BlogRecentPosts', elem: 'PostImage' }}>
                            <Image
                                src={first_image}
                                ratio="16x9"
                                isPlaceholder={!first_image}
                                mix={{
                                  block: 'BlogRecentPosts',
                                  elem: 'Image',
                              }}
                            />
                        </Link>
                        <div block="BlogRecentPosts" elem="PostInfo">
                            <div block="BlogRecentPosts" elem="PostTitle">
                                <Link
                                    to={postUrl}
                                    title={title}
                                    mix={{ block: 'TypographyParagraph', mods: { variant: 'large' } }}
                                >
                                    <h3>
                                        <TextPlaceholder
                                          mix={ { block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' } }
                                          content={ title }
                                          length="short"
                                        />
                                    </h3>
                                </Link>
                            </div>
                            <div block="BlogRecentPosts" elem="ReadMore">
                                <Link to={ postUrl } title={ __('Read more') }>
                                    {__('Read more')}
                                    <Icons name="arrow_right" width="18" />
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            },
            renderContents(args, callback, instance) {
                const { posts, isLoaded } = instance.props;
                const post = {};

                if (!isLoaded) {
                    return Array(MAX_RECENT_POSTS)
                        .fill()
                        .map((_, i) => instance.renderCard(post, i));
                }

                return (
                    <div block="BlogRecentPosts" elem="Posts">
                        {posts.map(instance.renderCard)}
                    </div>
                );
            },
            render(args, callback, instance) {
                return (
                    <div block="BlogRecentPosts" elem="Wrapper">
                        <div block="BlogRecentPosts" elem="SectionTitle">
                            <TypographyHeader tag="h4" variant="medium">
                                {__('Recent Posts')}
                            </TypographyHeader>
                        </div>

                        { instance.renderContents() }
                    </div>
                );
            },
        }
    }
};
