import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import {
    DEFAULT_SLICK_SETTINGS,
    SLIDER_DESKTOP_HEIGHT,
    SLIDER_MOBILE_HEIGHT,
    SLIDER_TABLET_HEIGHT,
} from 'Component/BannerSlider/BannerSlider.config';
import Button from 'Component/Button';
import Loader from 'Component/Loader';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import TypographyHeader from 'Component/TypographyHeader';
import media from 'Util/Media';

import './BannerSlider.style';

/** @namespace Pwa/Component/BannerSlider/Component */
export class BannerSlider extends PureComponent {
    static propTypes = {
        slider: PropTypes.object.isRequired,
        settings: PropTypes.object,
        isMobile: PropTypes.bool,
        isTablet: PropTypes.bool,
    };

    static defaultProps = {
        settings: {},
    };

    renderSliderSlides(slider) {
        return slider.slides.map(
            (
                {
                    body,
                    header,
                    image,
                    tablet_image: tabletImage,
                    mobile_image: mobileImage,
                    cta_text: ctaText,
                    cta_link: ctaLink,
                },
                index
            ) => (
                <div key={`slider${index}`}>
                    {image && (
                        <picture>
                            {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                            {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                            <img src={media(image)} alt={header} />
                        </picture>
                    )}

                    {(body || ctaText) && (
                        <div block="BannerSlider" elem="Content">
                            {body ? (
                                <TypographyHeader variant="medium" tag="h2">
                                    {body}
                                </TypographyHeader>
                            ) : null}
                            {ctaText ? (
                                <Button asLink variant="outlineDark" to={ctaLink}>
                                    {ctaText}
                                </Button>
                            ) : null}
                        </div>
                    )}
                </div>
            )
        );
    }

    renderCustomPaging(i) {
        const { slider } = this.props;

        return (
            <div block="BannerSlider" elem="Page">
                {slider.slides[i].header}
            </div>
        );
    }

    render() {
        const { slider, settings, isMobile, isTablet } = this.props;
        let loaderHeight = SLIDER_DESKTOP_HEIGHT;

        if (isMobile) {
            loaderHeight = SLIDER_MOBILE_HEIGHT;
        }

        if (isTablet) {
            loaderHeight = SLIDER_TABLET_HEIGHT;
        }

        if (!slider || !slider?.slides) {
            return <Loader displayRelative height={loaderHeight} />;
        }

        return (
            <div block="BannerSlider">
                <SlickSlider
                    customPaging={this.renderCustomPaging.bind(this)}
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                >
                    {this.renderSliderSlides(slider)}
                </SlickSlider>
            </div>
        );
    }
}

export default BannerSlider;
