import Field from 'Component/Field';
import FieldGroup from 'Component/FieldGroup';
import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

/** @namespace Pwa/Component/FieldForm/Component */
export class FieldForm extends SourceFieldForm {
    renderSection(section) {
        const { fields, attr: { name = '' } = {}, name: sectionName, component, visible } = section;

        if (component && visible) {
            return component();
        }

        if (component && !visible) {
            return null;
        }

        if (Array.isArray(fields)) {
            return (
                <FieldGroup {...section} key={name || sectionName}>
                    {fields.map(this.renderSection.bind(this))}
                </FieldGroup>
            );
        }

        return <Field {...section} key={name} />;
    }
}

export default FieldForm;
