import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';

import { POST_DETAILS_CATALOG_WIDGET_SLICK_SETTINGS } from 'Component/CatalogWidget/CatalogWidget.config';
import CatalogWidgetQuery from 'Query/CatalogWidget.query';
import DataContainer from 'Util/Request/DataContainer';

import CatalogWidget from './CatalogWidget.component';

/** @namespace Pwa/Component/CatalogWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productUrlSuffix: state.ConfigReducer.product_url_suffix,
});

/** @namespace Pwa/Component/CatalogWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/CatalogWidget/Container */
export class CatalogWidgetContainer extends DataContainer {
    static propTypes = {
        code: PropTypes.string.isRequired,
        productUrlSuffix: PropTypes.string,
        pageType: PropTypes.string,
    };

    static defaultProps = {
        productUrlSuffix: '',
    };

    state = {
        items: [],
        title: null,
        isLoading: false,
    };

    containerFunctions = {};

    componentDidMount() {
        this.getCatalogWidgetData();
    }

    performDataTransformations(data) {
        const { productUrlSuffix } = this.props;

        return data.map((item) => ({
            ...item,
            id: item.entity_id,
            url: `${item.url_key}${productUrlSuffix}`,
            image: {
                url: item.image,
            },
            small_image: {
                url: item.image,
            },
            variants: !item.variants ? [] : item.variants,
        }));
    }

    getCatalogWidgetData() {
        const { code } = this.props;

        this.setState({ isLoading: true });

        this.fetchData(
            [CatalogWidgetQuery.getCatalogWidgetQuery(code, { isPlp: true, noVariantAttributes: true })],
            ({ catalogWidget: { items, title } }) => {
                this.setState({
                    items: this.performDataTransformations(items),
                    title,
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    items: [],
                    title: null,
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { items, title, isLoading } = this.state;
        const { pageType } = this.props;

        return {
            items,
            title,
            isLoading,
            settings: pageType === 'postDetails' ? POST_DETAILS_CATALOG_WIDGET_SLICK_SETTINGS : {},
        };
    }

    render() {
        return (
            <LazyLoad once offset={100}>
                <CatalogWidget {...this.containerFunctions} {...this.containerProps()} />
            </LazyLoad>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogWidgetContainer);
