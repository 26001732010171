import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps } from 'Component/MyAccountAddressForm/MyAccountAddressForm.container';
import { CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer } from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/CheckoutAddressForm/Container */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);
