/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const NO_CATEGORIES = 'NO_CATEGORIES';

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace Mirat/Blog/Store/Categories/Action/updateCategories */
export const updateCategories = (categories) => ({
    type: UPDATE_CATEGORIES,
    categories,
    isLoaded: true,
});

/**
 * @param message
 * @returns {{type: string, message: *}}
 * @namespace Mirat/Blog/Store/Categories/Action/categoriesException */
export const categoriesException = (message) => ({
    type: NO_CATEGORIES,
    message,
});
