import { withRouter } from 'react-router-dom';

import DiscountLabel from 'Component/DiscountLabel/DiscountLabel.component';
import Image from 'Component/Image';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Label from 'Component/Label';
import Loader from 'Component/Loader';
import { MAX_NAME_LENGTH } from 'Component/ProductCard/ProductCard.config';
import ProductReviewRating from 'Component/ProductReviewRating';
import RelatedProductsByAttribute from 'Component/RelatedProductsByAttribute/RelatedProductsByAttribute.container';
import TypographyHeader from 'Component/TypographyHeader';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { roundPercentage } from 'Util/Percentage/Percentage';

import productPlaceholder from '../../assets/images/productPlaceholder.png';

/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCard extends SourceProductCard {
    state = {
        relatedImage: null,
    };

    renderLabels() {
        const {
            product: { labels },
        } = this.props;

        return labels && labels.map((label, index) => <Label {...label} key={`label${index}`} />);
    }

    renderDiscountLabel() {
        const {
            productPrice: {
                price: {
                    discount: { percentOff },
                },
            },
        } = this.props;

        return (
            <DiscountLabel value={roundPercentage(percentOff)} mix={{ block: 'ProductCard', elem: 'DiscountLabel' }} />
        );
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
        } = this.props;

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={thumbnail}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        );

        return thumbnail ? (
            <>
                {this.sharedComponent}
                <ImageLazyLoading
                    style={{ display: 'none' }}
                    alt={name}
                    src={thumbnail}
                    lazyLoadingType="productTile"
                />
            </>
        ) : (
            <img src={productPlaceholder} alt={name} />
        );
    }

    renderVisibleOnHover() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                {this.renderDeliveryTime()}
                {this.renderRelatedProductsByAttribute()}
            </>
        );
    }

    renderDeliveryTime() {
        const {
            product: { calculated_delivery_time },
        } = this.props;

        return calculated_delivery_time && calculated_delivery_time !== '' ? (
            <div block="ProductCard" elem="DeliveryTime">
                {__('Delivery time')}: <span>{calculated_delivery_time}</span>
            </div>
        ) : null;
    }

    renderAlternativePicture() {
        const {
            product: { id, name, alternative_hover_image },
        } = this.props;

        return alternative_hover_image ? (
            <Image
                src={alternative_hover_image}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        ) : null;
    }

    renderRelatedProductsByAttribute() {
        const {
            product: { products_in_series },
        } = this.props;

        return products_in_series ? (
            <RelatedProductsByAttribute
                series={products_in_series}
                variant="secondary"
                onMouseEnter={(image) => {
                    this.setState({
                        relatedImage: image,
                    });
                }}
                onMouseLeave={() => {
                    this.setState({
                        relatedImage: null,
                    });
                }}
            />
        ) : null;
    }

    renderRelatedProduct() {
        const { relatedImage } = this.state;

        return relatedImage ? (
            <Image
                src={relatedImage}
                alt="dasdad"
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={false}
            />
        ) : null;
    }

    renderName() {
        const {
            product: { name },
            device,
        } = this.props;
        let strippedName = null;

        if (device.isMobile && name && name.length > MAX_NAME_LENGTH) {
            strippedName = name.substring(0, MAX_NAME_LENGTH);
        }

        return (
            <TypographyHeader block="ProductCard" elem="Name" mods={{ isLoaded: !!name }} variant="normal" tag="h3">
                {strippedName ? `${strippedName}...` : name}
            </TypographyHeader>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderDiscountLabel()}
                <div block="ProductCard" elem="LabelsWithActions">
                    <div block="ProductCard" elem="Labels">
                        {this.renderLabels()}
                    </div>
                    {this.renderProductActions()}
                </div>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            <div block="ProductCard" elem="Price">
                                {this.renderPrice()}
                            </div>
                            {this.renderName()}
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <div block="ProductCard" elem="Labels">
                                {this.renderDiscountLabel()}
                                {this.renderLabels()}
                            </div>
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content" mods={{ layout }}>
                            <div block="ProductCard" elem="MainInfo">
                                {this.renderMainDetails()}
                                {this.renderBrand()}
                                {this.renderPrice()}
                            </div>
                            <div block="ProductCard" elem="ActionWrapper">
                                {this.renderProductActions()}
                            </div>
                            <div block="ProductCard" elem="AdditionalContent">
                                {children}
                            </div>
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    renderRatingSummary() {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} showCounter={false} />;
    }

    render() {
        const { children, mix, isLoading, layout, sliderMode } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={{ layout }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={{ layout, isSlider: sliderMode }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default withRouter(ProductCard);
