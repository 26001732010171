import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/Overlay/Overlay.container';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import Overlay from './Overlay.component';

export { mapStateToProps };

/** @namespace Pwa/Component/Overlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: (state) => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE, state)),
});

/** @namespace Pwa/Component/Overlay/Container */
export class OverlayContainer extends PureComponent {
    static propTypes = {
        ...Overlay.propTypes,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
    };

    containerProps() {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;

        return {
            ...this.props,
            hideActiveOverlay,
            goToPreviousNavigationState,
        };
    }

    render() {
        return <Overlay {...this.containerProps()} />;
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OverlayContainer);
