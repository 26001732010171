import Button from 'Component/Button';
import CheckoutSuccessDetails from 'Component/CheckoutSuccessDetails';
import CheckoutSuccessProduct from 'Component/CheckoutSuccessProduct';
import CheckoutSuccessSummary from 'Component/CheckoutSuccessSummary';
import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';

import './CheckoutSuccess.override.style';

/** @namespace Pwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccess extends SourceCheckoutSuccess {
    renderProducts() {
        const {
            orderID: {
                placeOrder: {
                    order: {
                        order_products,
                        base_order_info: { currency_code },
                    },
                },
            },
        } = this.props;

        return order_products.map(
            ({ name, calculated_delivery_time, original_price, qty, row_total, image, unit_price }) => (
                <CheckoutSuccessProduct
                    key={name}
                    name={name}
                    deliveryTime={calculated_delivery_time}
                    originalPrice={original_price}
                    qty={qty}
                    rowTotal={row_total}
                    image={image}
                    currencyCode={currency_code}
                    unitPrice={unit_price}
                />
            )
        );
    }

    renderProductsInfo() {
        return (
            <div block="CheckoutSuccess" elem="Products-Info">
                <p>{__('Product')}</p>
                <p>{__('Price per unit')}</p>
                <p>{__('Qty')}</p>
                <p>{__('Cost')}</p>
            </div>
        );
    }

    renderProductsInfoMobile() {
        return (
            <div block="CheckoutSuccess" elem="Products-InfoMobile">
                <p>{__('Products summary')}</p>
            </div>
        );
    }

    renderProductsSection() {
        return (
            <div block="CheckoutSuccess" elem="ProductsSection">
                {this.renderProductsInfo()}
                {this.renderProductsInfoMobile()}
                <div block="CheckoutSuccess" elem="Products">
                    {this.renderProducts()}
                </div>
            </div>
        );
    }

    renderSummarySection() {
        const {
            orderID: {
                placeOrder: {
                    order: {
                        fee,
                        shipping_and_handling,
                        subtotal,
                        total,
                        base_order_info: { currency_code },
                    },
                },
            },
        } = this.props;

        return (
            <CheckoutSuccessSummary
                subtotal={subtotal}
                fee={fee}
                shippingAndHandling={shipping_and_handling}
                total={total}
                currencyCode={currency_code}
            />
        );
    }

    renderDetailsSection() {
        const {
            orderID: {
                placeOrder: {
                    order: {
                        billing_address,
                        payment_info: {
                            additional_information: { instructions },
                        },
                        shipping_info: { shipping_address, shipping_description },
                    },
                },
            },
        } = this.props;

        return (
            <CheckoutSuccessDetails
                billingAddress={billing_address}
                shippingAddress={shipping_address}
                instructions={instructions}
                shippingDescription={shipping_description}
            />
        );
    }

    renderGoToPaymentSection() {
        const {
            orderID: {
                placeOrder: {
                    order: { url, ca_raty_form },
                },
            },
        } = this.props;

        if (!url && !ca_raty_form) {
            return null;
        }

        return (
            <div block="CheckoutSuccess" elem="PaymentRedirect">
                {url ? (
                    <div block="CheckoutSuccess" elem="PaymentRedirectText">
                        {__(
                            'You will be automatically redirected to payment in 5 seconds. If not, click on the button below.'
                        )}
                    </div>
                ) : null}
                <div block="CheckoutSuccess" elem="PaymentRedirectButton">
                    {ca_raty_form ? (
                        <div
                            block="CheckoutSuccess"
                            elem="PaymentCAText"
                            dangerouslySetInnerHTML={{ __html: ca_raty_form }}
                        />
                    ) : null}
                    {url ? (
                        <Button asLink to={url}>
                            {__('Make a payment')}
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        const {
            orderID: {
                placeOrder: {
                    order: { order_number },
                },
            },
        } = this.props;

        return (
            <div block="CheckoutSuccess">
                <h3>
                    {__('Your order number is: %s')}
                    <span block="CheckoutSuccess" elem="OrderNumber">
                        {order_number}
                    </span>
                </h3>
                <p>{__('We`ll send you an email with order confirmation with and tracking info.')}</p>
                <p>{__('We`ll send you an email about next checkout steps also')}</p>
                {this.renderGoToPaymentSection()}
                {this.renderProductsSection()}
                {this.renderSummarySection()}
                {this.renderDetailsSection()}
            </div>
        );
    }
}

export default CheckoutSuccess;
