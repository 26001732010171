import { CheckoutAddressTable as SourceCheckoutAddressTable } from 'SourceComponent/CheckoutAddressTable/CheckoutAddressTable.component';

import './CheckoutAddressTable.style';

/** @namespace Pwa/Component/CheckoutAddressTable/Component */
export class CheckoutAddressTable extends SourceCheckoutAddressTable {
    get dataPairArray() {
        const { address, showAdditionalFields } = this.props;

        const nameAndSurname = {
            nameAndSurname: `${address.firstname} ${address.lastname}`,
        };

        const additionalFields = [
            {
                key: 'postcode',
                label: __('Postal code'),
                source: address,
            },
            {
                key: 'telephone',
                label: __('No. phone'),
                source: address,
            },
        ];

        return [
            {
                key: 'nameAndSurname',
                label: __('First name and last name'),
                source: nameAndSurname,
            },
            {
                key: 'street',
                label: __('Street'),
                source: address,
            },
            ...(showAdditionalFields ? additionalFields : []),
        ];
    }
}

export default CheckoutAddressTable;
