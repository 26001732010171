import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { formatPrice } from 'Util/Price';

import productPlaceholder from '../../assets/images/productPlaceholder.png';

import './CheckoutSuccessProduct.style';

/** @namespace Pwa/Component/CheckoutSuccessProduct/Component */
export class CheckoutSuccessProduct extends PureComponent {
    render() {
        const { name, deliveryTime, originalPrice, qty, rowTotal, currencyCode, image, unitPrice } = this.props;
        const isSpecial = unitPrice !== originalPrice;

        return (
            <div block="CheckoutSuccessProduct">
                <div block="CheckoutSuccessProduct" elem="Box">
                    <img src={image?.url ? image.url : productPlaceholder} alt={name} />
                    <div block="CheckoutSuccessProduct" elem="SubBox">
                        <p>{name}</p>
                        <div block="CheckoutSuccessProduct" elem="DeliveryBox">
                            <Icons name="clock" fill="#63b69e" width="20" height="20" />
                            <span>
                                {__('Delivery time')}: <strong>{deliveryTime || __('No data')}</strong>
                            </span>
                        </div>
                    </div>
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Price per unit')}
                    </p>

                    {isSpecial && (
                        <p block="CheckoutSuccessProduct" elem="SpecialPrice" mods={{ specialPrice: !!isSpecial }}>
                            {formatPrice(unitPrice, currencyCode)}
                        </p>
                    )}
                    <p block="CheckoutSuccessProduct" elem="OriginalPrice" mods={{ isCrossed: !!isSpecial }}>
                        {formatPrice(originalPrice, currencyCode)}
                    </p>
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Qty')}
                    </p>

                    {qty}
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Cost')}
                    </p>

                    <p block="CheckoutSuccessProduct" elem="OriginalPrice">
                        {formatPrice(rowTotal, currencyCode)}
                    </p>
                </div>
            </div>
        );
    }
}

export default CheckoutSuccessProduct;
