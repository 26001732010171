import Icons from 'Component/Icons';
import Loader from 'Component/Loader';
import { ProductCompareButton as SourceProductCompareButton } from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.style';

/** @namespace Pwa/Component/ProductCompareButton/Component */
export class ProductCompareButton extends SourceProductCompareButton {
    render() {
        const { handleClick, isActive, isLoading, mix } = this.props;

        return (
            <div block="ProductCompareButton" mods={{ isActive }} mix={mix}>
                <button block="ProductCompareButton" elem="Button" onClick={handleClick} aria-label={__('Compare')}>
                    <Icons name="compare" width="20" height="20" />
                </button>
                <Loader isLoading={isLoading} />
            </div>
        );
    }
}

export default ProductCompareButton;
