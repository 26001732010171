import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import Tabs from './Tabs.component';

/** @namespace Pwa/Component/Tabs/Container */
export class TabsContainer extends PureComponent {
    static propTypes = {
        'data-tabs': PropTypes.string.isRequired,
        children: ChildrenType.isRequired,
    };

    containerFunctions = {
        handleTabClick: this.handleTabClick.bind(this),
    };

    state = {
        activeTab: 1,
    };

    componentDidMount() {
        this.changeActiveTab();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTab } = this.state;

        if (prevState.activeTab !== activeTab) {
            this.changeActiveTab();
        }
    }

    changeActiveTab() {
        const tabs = document.querySelectorAll('.Tabs-Content');
        const { activeTab } = this.state;

        tabs.forEach((contentTab, index) => {
            if (index + 1 === activeTab && !contentTab.classList.contains('Tabs-Content_isActive')) {
                contentTab.classList.add('Tabs-Content_isActive');
            } else {
                contentTab.classList.remove('Tabs-Content_isActive');
            }
        });
    }

    handleTabClick(id) {
        this.setState({ activeTab: id });
    }

    getArrayTabs() {
        const { 'data-tabs': dataTabs } = this.props;

        const tabNames = JSON.parse(dataTabs.replace(/'/g, '"'));
        const tabs = [];

        tabNames.forEach((tabName, index) => {
            tabs.push({ name: tabName, id: index + 1 });
        });

        return tabs;
    }

    containerProps() {
        const { children } = this.props;
        const { activeTab } = this.state;

        return {
            tabs: this.getArrayTabs(),
            children,
            activeTab,
        };
    }

    render() {
        return <Tabs {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default TabsContainer;
