import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace Pwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    static propTypes = {
        ...SourceField.propTypes,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        variant: PropTypes.oneOf(['primary', 'secondary', 'teriary', 'outline']),
        size: PropTypes.oneOf(['small', 'normal']),
    };

    static defaultProps = {
        ...SourceField.defaultProps,
        variant: 'primary',
        size: 'normal',
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.tel]: this.renderTel.bind(this),
    };

    renderTel() {
        const { type, setRef, attr, events, isDisabled } = this.props;

        return <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />;
    }

    // Renders fields label above field
    renderLabel() {
        const { type, label, attr: { name } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={name || `input-${type}`}>
                    {`${label}:`}
                    {this.renderRequiredTag()}
                </label>
            </div>
        );
    }

    renderCheckboxOrRadio() {
        const { type, setRef, attr, attr: { id = '', checked = false } = {}, events, isDisabled, label } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);

        return (
            <label htmlFor={id} block="Field" elem={`${elem}Label`} mods={{ disabled: isDisabled, checked }}>
                <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />
                <div block="input-control" mods={{ disabled: isDisabled }} />
                <div block="Field" elem={`${elem}LabelContent`}>
                    {label}
                </div>
            </label>
        );
    }

    renderError(message, key) {
        return (
            <div block="Field" elem="ErrorMessage" key={key}>
                {message}
            </div>
        );
    }

    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name },
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                {errorMessages.map((message, index) => this.renderError.call(this, message, name + index))}
            </div>
        );
    }

    render() {
        const { type, validationResponse, mix, variant, size } = this.props;
        const inputRenderer = this.renderMap[type];

        return (
            <div block="Field" elem="Wrapper" mods={{ type }}>
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        variant,
                        size,
                    }}
                    mix={mix}
                >
                    {type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel()}
                    {inputRenderer && inputRenderer()}
                    {this.renderErrorMessages()}
                </div>

                {this.renderSubLabel()}
            </div>
        );
    }
}

export default FieldComponent;
