import DiscountLabel from 'Component/DiscountLabel/DiscountLabel.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { roundPercentage } from 'Util/Percentage/Percentage';

import './ProductPrice.style';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPrice extends SourceProductPrice {
    priceLabelTypeMap = {
        [PRODUCT_TYPE.simple]: __('From'),
        [PRODUCT_TYPE.virtual]: __('From'),
        [PRODUCT_TYPE.bundle]: __('From'),
        [PRODUCT_TYPE.grouped]: __('From'),
        [PRODUCT_TYPE.downloadable]: __('From'),
        [PRODUCT_TYPE.configurable]: __('From'),
    };

    renderPriceWithTax(basePrice, taxPrice, label) {
        return <>{this.renderPrice(basePrice, label)}</>;
    }

    renderRegularPrice(price) {
        const { value, valueFormatted } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="HighPrice">
                {valueFormatted}
            </span>
        );
    }

    renderDiscountLabel() {
        const { discountPercentage } = this.props;

        return <DiscountLabel value={roundPercentage(discountPercentage)} />;
    }

    renderOldPrice() {
        const {
            price: { originalPrice: { value: originalPriceValue, valueFormatted: originalPriceFormatted } = {} } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <>
                {this.renderDiscountLabel()}
                <span
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={__('Old product price')}
                    itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
                >
                    {originalPriceFormatted}
                </span>
            </>
        );
    }

    renderPrice(price, label) {
        const { value: priceValue, valueFormatted: priceFormatted = 0 } = price;
        const { discountPercentage } = this.props;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span
                    itemProp={itemProp}
                    content={content}
                    block="ProductPrice"
                    elem="PriceValue"
                    mods={{ isSpecialPrice: !!discountPercentage }}
                >
                    {priceFormatted}
                </span>
            </span>
        );
    }

    render() {
        const {
            price: { finalPrice, originalPrice, finalPrice: { value: finalPriceValue = 0 } = {} } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <p
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0, isPreview }}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
                {priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice()}
            </p>
        );
    }
}

export default ProductPrice;
