import myAccountAddressForm from 'Component/MyAccountAddressForm/MyAccountAddressForm.form';
import { MyAccountAddressForm as SourceMyAccountAddressForm } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import './MyAccountAddressForm.style.scss';

/** @namespace Pwa/Component/MyAccountAddressForm/Component */
export class MyAccountAddressForm extends SourceMyAccountAddressForm {
    // #region GETTERS
    get fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
        } = this.props;

        return myAccountAddressForm(
            {
                address,
                countries,
                addressLinesQty,
                regionDisplayAll,
                showVatNumber,
                defaultCountry,
                availableRegions,
                isStateRequired,
                countryId,
                currentRegion,
                currentCity,
                currentRegionId,
                currentZipcode,
                ...address,
            },
            {
                onCountryChange,
                onZipcodeChange,
                onCityChange,
                onRegionChange,
                onRegionIdChange,
            }
        );
    }
}

export default MyAccountAddressForm;
