import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import Accordion from './Accordion.component';

/** @namespace Pwa/Component/Accordion/Container */
export class AccordionContainer extends PureComponent {
    static propTypes = {
        'data-accordion': PropTypes.string.isRequired,
        'data-device': PropTypes.oneOf(['mobile', 'mobileAndTablet']).isRequired,
        children: ChildrenType.isRequired,
    };

    componentDidMount() {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.addEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    componentWillUnmount() {
        const accordionBoxes = document.querySelectorAll('.Accordion-Box');

        accordionBoxes.forEach((accordionBox) => {
            accordionBox.removeEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    setActiveAccordionItem(clickedItem) {
        const boxes = document.querySelectorAll('.Accordion-Box');
        boxes.forEach((box) => {
            if (clickedItem !== box) {
                box.classList.remove('Accordion-Box_isOpen');
            }
        });
        clickedItem.classList.toggle('Accordion-Box_isOpen');
    }

    handleClickBox(e) {
        const box = e.target.closest('.Accordion-Box');
        this.setActiveAccordionItem(box);
    }

    containerProps() {
        const { children, 'data-device': dataDevice } = this.props;

        return {
            children,
            dataDevice,
        };
    }

    render() {
        return <Accordion {...this.containerProps()} />;
    }
}

export default AccordionContainer;
