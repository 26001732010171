import PropTypes from 'prop-types';

import { FieldNumberContainer as SourceFieldNumberContainer } from 'SourceComponent/FieldNumber/FieldNumber.container';

/** @namespace Pwa/Component/FieldNumber/Container */
export class FieldNumberContainer extends SourceFieldNumberContainer {
    static propTypes = {
        ...SourceFieldNumberContainer.propTypes,
        value: PropTypes.number,
    };
}

export default FieldNumberContainer;
