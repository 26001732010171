import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import handleViewport from 'react-in-viewport';

import { ROOT_MARGIN } from './PromoBannerAnalytics.config';

/** @namespace Pwa/Component/PromoBannerAnalytics/Component */
export class PromoBannerAnalytics extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        const { children } = this.props;

        return <div>{children}</div>;
    }
}

export default handleViewport(PromoBannerAnalytics, { rootMargin: ROOT_MARGIN });
