import { fetchQuery } from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';

const getPurchase = (orderID, event) => {
    const query = GtmPurchaseQuery.getGTMPurchase(orderID);

    fetchQuery(query).then(({ purchase }) => {
        event(EVENT_GTM_PURCHASE, { ...purchase, orderID });
    });
};

const render = (args, callback, instance) => {
    const {
        orderID: {
            placeOrder: {
                order: { order_number },
            },
        },
        event,
    } = instance.props;

    if (order_number) {
        getPurchase(order_number, event);
    }

    return callback.apply(instance, args);
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render,
        },
    },
};
