import PropTypes from 'prop-types';

import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';

/** @namespace Pwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        ...SourceImageContainer.propTypes,
        useNativeLazyLoading: PropTypes.bool,
        lazyLoadingType: PropTypes.oneOf(['productTile']),
    };

    static defaultProps = {
        ...SourceImageContainer.defaultProps,
        useNativeLazyLoading: true,
        lazyLoadingType: null,
    };

    containerProps() {
        const { useNativeLazyLoading, lazyLoadingType } = this.props;

        return {
            ...super.containerProps(),
            useNativeLazyLoading,
            lazyLoadingType,
        };
    }
}

export default ImageContainer;
