import CheckoutPayments from 'Component/CheckoutPayments';

const renderPayments = (args, callback, instance) => {
    const {
        paymentMethods,
        onPaymentMethodSelect,
        setLoading,
        setDetailsStep,
        shippingAddress,
        savePaymentInformationOnly
    } = instance.props;

    if (!paymentMethods.length) {
        return null;
    }

    return (
        <CheckoutPayments
          setLoading={ setLoading }
          setDetailsStep={ setDetailsStep }
          paymentMethods={ paymentMethods }
          onPaymentMethodSelect={ onPaymentMethodSelect }
          setOrderButtonVisibility={ instance.setOrderButtonVisibility }
          billingAddress={ shippingAddress }
          setOrderButtonEnableStatus={ instance.setOrderButtonEnableStatus }
          savePaymentInformationOnly={savePaymentInformationOnly}
        />
    );
};

export default {
    'Pwa/Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments
        }
    }
};
