import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

/** @namespace Pwa/Component/Button/Component */
export class ButtonComponent extends PureComponent {
    static propTypes = {
        variant: PropTypes.oneOf([
            'primary',
            'secondary',
            'outline',
            'outlineInverse',
            'outlineDark',
            'outlineDarkTransparentBg',
            'likeLink',
            'iconOnly',
        ]),
        size: PropTypes.oneOf(['large', 'medium', 'small']),
        asLink: PropTypes.bool,
        iconBefore: PropTypes.node,
        iconAfter: PropTypes.node,
    };

    static defaultProps = {
        variant: 'primary',
        size: 'large',
        asLink: false,
    };

    render() {
        const { children, variant, size, asLink, iconBefore, iconAfter, ...rest } = this.props;
        const Tag = asLink ? Link : 'button';

        return (
            <Tag block="Button" mods={{ variant, size }} {...rest}>
                {iconBefore}
                {children}
                {iconAfter}
            </Tag>
        );
    }
}

export default ButtonComponent;
