const postField = (args, callback, instance) => {
    const fields = callback.apply(instance, args);

    fields.push('views_count');

    return fields;
};

export default {
    'Mirat/Blog/Query/BlogPost/Query/BlogPostQuery': {
        'member-function': {
            postField
        }
    }
};
