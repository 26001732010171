import { getFormattedRegion } from 'Util/Address';

export * from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.table';

/** @namespace Pwa/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address, countries, showAdditionalFields } = props;

    const regionData = getFormattedRegion(address, countries);

    const nameAndSurname = {
        nameAndSurname: `${address.firstname} ${address.lastname}`,
    };

    const additionalFields = [
        {
            key: 'city',
            label: __('Town'),
            source: address,
        },
        {
            key: 'postcode',
            label: __('Postal code'),
            source: address,
        },
        {
            key: 'region',
            label: __('State/Province'),
            source: regionData,
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData,
        },
        {
            key: 'telephone',
            label: __('No. phone'),
            source: address,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
    ];

    return [
        {
            key: 'nameAndSurname',
            label: __('First name and last name'),
            source: nameAndSurname,
        },
        {
            key: 'street',
            label: __('Street'),
            source: address,
        },
        ...(showAdditionalFields ? additionalFields : []),
    ];
};
