import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Html from 'Component/Html';
import { STRING_ONLY_ATTRIBUTE_CODES } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace Pwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {
    getOptionLabel(value) {
        const {
            attribute: { attribute_options },
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];

            if (optionValues) {
                const { label } = optionValues;

                return { ...optionValues, labelText: label };
            }
        }

        return {};
    }

    getCheckboxLabel = (label, subLabel) => {
        const { isProductCountVisible } = this.props;

        return (
            <div block="ProductAttributeValue" elem="Label">
                <div block="ProductAttributeValue" elem="LabelText">
                    {label}
                </div>
                {isProductCountVisible ? (
                    <div block="ProductAttributeValue" elem="Count">
                        {subLabel}
                    </div>
                ) : null}
            </div>
        );
    };

    renderSelectAttribute() {
        const {
            attribute: { attribute_value, attribute_code, has_swatch },
        } = this.props;

        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, labelText, count, swatch_data } = attributeOption;

        // skip attributes without valid swatches
        if (!swatch_data && has_swatch) {
            return null;
        }

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(labelText || __('N/A'), null, count);
        }

        const { value, type } = swatch_data;

        switch (type) {
            case '0':
                return this.renderStringValue(value, labelText, count);
            case '1':
                return this.renderColorValue(value, label, count);
            case '2':
                return this.renderImageValue(value, label, count);
            default:
                return this.renderStringValue(labelText || __('N/A'), labelText, count);
        }
    }

    renderDropdown(value, subLabel) {
        const {
            isSelected,
            attribute: { attribute_code },
        } = this.props;

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    id: `${attribute_code}${value}`,
                    name: `${attribute_code}${value}`,
                    checked: isSelected,
                }}
                events={{ onChange: () => {} }}
                label={this.getCheckboxLabel(value, subLabel)}
            />
        );
    }

    renderColorValue(color, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#a1a1a1' : color,
            '--option-check-mark-background': isLight ? '#000' : '#fff',
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0,
        };

        return (
            <data block="ProductAttributeValue" elem="ColorSwatch" title={label}>
                <div block="ProductAttributeValue" elem="ColorSwatchColor" style={style} />
                {this.renderDropdown(label, count)}
            </data>
        );
    }

    renderImageValue(img, label, count) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-check-mark-background': '#fff',
            '--option-is-selected': isSelected ? 1 : 0,
        };

        return (
            <data block="ProductAttributeValue" elem="ImageSwatch" title={label}>
                <div block="ProductAttributeValue" elem="ImageSwatchImage" style={style}>
                    <img src={`/media/attribute/swatch${img}`} alt={label} />
                </div>
                {this.renderDropdown(label, count)}
            </data>
        );
    }

    renderAttributeByType() {
        const {
            attribute: { attribute_type },
        } = this.props;

        switch (attribute_type) {
            case 'select':
                return this.renderSelectAttribute();
            case 'boolean':
                return this.renderBooleanAttribute();
            case 'text':
                return this.renderTextAttribute();
            case 'multiselect':
                return this.renderMultiSelectAttribute();
            case 'media_image':
                return this.renderImageAttribute();
            case 'textarea':
                return this.renderTextAreaAttribute();
            case 'weight':
                return this.renderNumericAttribute();
            case 'price':
                return this.renderNumericAttribute();
            default:
                return this.renderPlaceholder();
        }
    }

    renderStringValue(value, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return <span dangerouslySetInnerHTML={{ __html: label || value || __('N/A').toString() }} />;
        }

        if (!isSwatch) {
            return this.renderDropdown(value, count);
        }

        return (
            <span block="ProductAttributeValue" elem="String" mods={{ isSelected }} title={label}>
                <Html content={value} />
            </span>
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div block="ProductAttributeValue" mix={mix}>
                    {this.renderAttributeByType()}
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable }}
                    onClick={this.clickHandler}
                    onKeyDown={this.clickHandler}
                    role="link"
                    tabIndex="-1"
                    aria-hidden={isNotAvailable}
                    mix={mix}
                >
                    {this.renderAttributeByType()}
                </span>
            );
        }

        return (
            <>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a
                    href={href}
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable }}
                    onClick={this.clickHandler}
                    aria-hidden={isNotAvailable}
                    mix={mix}
                >
                    {this.renderAttributeByType()}
                </a>
            </>
        );
    }
}

export default ProductAttributeValue;
