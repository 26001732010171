import { getQueryParam } from 'SourceUtil/Url/Url';

export * from 'SourceUtil/Url/Url';

/** @namespace Pwa/Util/Url/parseCustomFiltersToObject */
export const parseCustomFiltersToObject = (location) => {
    if (!location || !getQueryParam('customFilters', location)) {
        return {};
    }

    return Object.fromEntries(
        getQueryParam('customFilters', location)
            .split(';')
            .map((filter) => filter.split(':'))
            .map(([name, value]) => {
                if (value.includes('_')) {
                    const vS = value.split('_');

                    return [
                        name,
                        {
                            min: parseInt(vS[0], 10) ?? 0,
                            max: parseInt(vS[1], 10) ?? 0,
                        },
                    ];
                }

                return [name, value];
            })
    );
};

/** @namespace Pwa/Util/Url/parseCustomFiltersToUrl */
export const parseCustomFiltersToUrl = (filters) => {
    if (!filters) {
        return '';
    }

    return Object.entries(filters)
        .map(([name, value]) => {
            if (value.min || value.max) {
                return [name, `${value.min}_${value.max}`];
            }

            return [name, value];
        })
        .map((filter) => filter.join(':'))
        .join(';');
};
