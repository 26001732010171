import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import './SliderPrevArrow.style.scss';

/** @namespace Pwa/Component/SliderPrevArrow/Component */
export class SliderPrevArrow extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderPrevArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <Icons name="arrow_left" width="25" />
            </div>
        );
    }
}

export default SliderPrevArrow;
