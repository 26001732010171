import { PureComponent } from 'react';

import './CheckoutSuccessDetails.style';

/** @namespace Pwa/Component/CheckoutSuccessDetails/Component */
export class CheckoutSuccessDetails extends PureComponent {
    render() {
        const { billingAddress, shippingAddress, shippingDescription, instructions } = this.props;
        const {
            firstname: billingFirstname,
            lastname: billingLastname,
            street: billingStreet,
            postcode: billingPostcode,
            city: billingCity,
            telephone: billingTelephone,
            vat_id: billingVatId,
        } = billingAddress;

        const {
            firstname: shippingFirstname,
            lastname: shippingLastname,
            street: shippingStreet,
            postcode: shippingPostcode,
            city: shippingCity,
            telephone: shippingTelephone,
        } = shippingAddress;

        return (
            <div block="CheckoutSuccessDetails" elem="Info" mods={{ isTransfer: !!instructions }}>
                <div block="CheckoutSuccessDetails" elem="InfoBox">
                    <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                        {__('Client adress')}
                    </p>
                    <div block="CheckoutSuccessDetails" elem="InfoBoxContent">
                        <div>
                            <span>{shippingFirstname}</span>
                            <span>{shippingLastname}</span>
                        </div>
                        <div>
                            <span>{shippingStreet}</span>
                        </div>
                        <div>
                            <span>{shippingPostcode}</span>
                            <span>{shippingCity}</span>
                        </div>
                        <div>
                            <span>{shippingTelephone}</span>
                        </div>
                    </div>
                </div>
                <div block="CheckoutSuccessDetails" elem="InfoBox">
                    <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                        {__('Invoice data')}
                    </p>
                    <div block="CheckoutSuccessDetails" elem="InfoBoxContent">
                        <div>
                            <span>{billingFirstname}</span>
                            <span>{billingLastname}</span>
                        </div>
                        <div>
                            <span>{billingVatId}</span>
                        </div>
                        <div>
                            <span>{billingStreet}</span>
                        </div>
                        <div>
                            <span>{billingPostcode}</span>
                            <span>{billingCity}</span>
                        </div>
                        <div>
                            <span>{billingTelephone}</span>
                        </div>
                    </div>
                </div>
                {instructions && (
                    <>
                        <div block="CheckoutSuccessDetails" elem="InfoBox">
                            <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                                {__('Payment form')}
                            </p>
                            <p>{__('Bank transfer')}</p>
                            <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                                {__('Delivery form')}
                            </p>
                            <p>{shippingDescription}</p>
                        </div>
                        <div block="CheckoutSuccessDetails" elem="InfoBox">
                            <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                                {__('Transfer data')}
                            </p>
                            <div block="CheckoutSuccessDetails" elem="InfoBoxContent">
                                <span>{instructions}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default CheckoutSuccessDetails;
