export class CheckoutPaymentsContainerPlugin {
    componentDidMount(args, callback, instance) {
        const { savePaymentInformationOnly } = instance.props;
        const { selectedPaymentCode } = instance.state;

        savePaymentInformationOnly(selectedPaymentCode);
    }
}
const { componentDidMount } = new CheckoutPaymentsContainerPlugin();

export default {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            componentDidMount,
        },
    },
};
