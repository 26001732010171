import { PureComponent } from 'react';

import { OPINIONS_CHECKBOX_DESCRIPTION_BLOCK } from 'Component/CheckoutCeneoOpineo/CheckoutCeneoOpineo.config';
import CmsBlock from 'Component/CmsBlock';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import './CheckoutCeneoOpineo.style.scss';

/** @namespace Pwa/Component/CheckoutCeneoOpineo/Component */
export class CheckoutCeneoOpineo extends PureComponent {
    renderCheckboxText() {
        return __('I want to share my opinion about shopping satisfaction');
    }

    isCheckboxChecked() {
        const { ceneoOpinieAccepted, opineoAccepted, ceneoOpinieEnabled, opineoEnabled } = this.props;

        if (ceneoOpinieEnabled) {
            if (opineoEnabled) {
                return ceneoOpinieAccepted && opineoAccepted;
            }

            return ceneoOpinieAccepted;
        }

        if (opineoEnabled) {
            return opineoAccepted;
        }

        return null;
    }

    checkboxOnChange() {
        const { ceneoOpinieEnabled, opineoEnabled, setCeneoOpinieOnCart, setOpineoOnCart } = this.props;

        if (ceneoOpinieEnabled) {
            setCeneoOpinieOnCart();
        }

        if (opineoEnabled) {
            setOpineoOnCart();
        }
    }

    renderCheckbox() {
        const { showMoreInfo, switchDescriptionVisibility } = this.props;

        return (
            <div block="CheckoutBilling" elem="CeneoOpineo">
                <Field
                    type={FIELD_TYPE.checkbox}
                    attr={{
                        id: 'ceneoOpineo',
                        name: 'ceneoOpineo',
                        value: 'ceneoOpineo',
                        checked: this.isCheckboxChecked(),
                    }}
                    events={{
                        onChange: () => this.checkboxOnChange(),
                    }}
                    mix={{ block: 'CheckoutBilling', elem: 'CeneoOpineo-Checkbox' }}
                />
                <label block="CheckoutBilling" elem="CeneoOpineoLabel" htmlFor="ceneoOpineo">
                    {this.renderCheckboxText()}{' '}
                    <span
                        block="CheckoutBilling"
                        elem="CeneoOpineoDescriptionTrigger"
                        onClick={switchDescriptionVisibility}
                    >
                        {showMoreInfo ? __('less') : __('more')}
                    </span>
                    <div block="CheckoutBilling" elem="CeneoOpineoDescription" mods={{ isVisible: showMoreInfo }}>
                        <CmsBlock identifier={OPINIONS_CHECKBOX_DESCRIPTION_BLOCK} />
                    </div>
                </label>
            </div>
        );
    }

    render() {
        const { ceneoOpinieEnabled, opineoEnabled } = this.props;

        if (!ceneoOpinieEnabled && !opineoEnabled) {
            return null;
        }

        return <div block="CheckoutCeneoOpineo">{this.renderCheckbox()}</div>;
    }
}

export default CheckoutCeneoOpineo;
