import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DiscountLabel.style';

/** @namespace Pwa/Component/DiscountLabel/Component */
export class DiscountLabel extends PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };

    render() {
        const { value, ...rest } = this.props;

        return value ? <span block="DiscountLabel" {...rest}>{`-${value}%`}</span> : null;
    }
}

export default DiscountLabel;
