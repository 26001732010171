import Field from 'Component/Field';
import { CheckoutPayment as SourceCheckoutPayment } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import banktransfer from '../../assets/icons/banktransfer.png';
import ca_raty from '../../assets/icons/ca_raty.png';
import cashondelivery from '../../assets/icons/cashondelivery.png';
import przelewy24_blik from '../../assets/icons/przelewy24_blik.png';
import przelewy24 from '../../assets/icons/przelewy24.png';

import './CheckoutPayment.style';

/** @namespace Pwa/Component/CheckoutPayment/Component */
export class CheckoutPayment extends SourceCheckoutPayment {
    render() {
        const {
            isSelected,
            method: { title, code },
        } = this.props;

        const icons = {
            przelewy24_blik,
            banktransfer,
            ca_raty,
            cashondelivery,
            przelewy24,
        };

        return (
            <li block="CheckoutPayment">
                <button
                    block="CheckoutPayment"
                    mods={{ isSelected }}
                    elem="Button"
                    type="button"
                    onClick={this.onClick}
                >
                    <Field
                        type="radio"
                        attr={{
                            id: `option-${title}`,
                            name: `option-${title}`,
                            checked: isSelected,
                        }}
                    />
                    {icons[code] && (
                        <img block="ProductAttributeValue" elem="MediaImage" src={icons[code]} alt={title} />
                    )}
                    {title}
                </button>
            </li>
        );
    }
}

export default CheckoutPayment;
