/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { MixType } from 'Type/Common.type';

import BlogSearchBar from './BlogSearchBar.component';
import { BLOG_SEARCH } from './BlogSearchBar.config';

/** @namespace Mirat/Blog/Component/BlogSearchBar/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace Mirat/Blog/Component/BlogSearchBar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
});

/** @namespace Mirat/Blog/Component/BlogSearchBar/Container/BlogSearchBarContainer */
export class BlogSearchBarContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        mix: MixType,
    };

    static defaultProps = {
        mix: {},
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            searchCriteria: '',
            isSearchVisible: false,
        };

        this.containerFunctions = {
            onSearchBarChange: this.onSearchBarChange.bind(this),
            onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
            onSearchBarClick: this.onSearchBarClick.bind(this),
        };
    }

    onSearchBarClick() {
        const { showOverlay } = this.props;
        const { isSearchVisible } = this.state;

        if (isSearchVisible) {
            return;
        }

        this.setState({ isSearchVisible: true });
        showOverlay(BLOG_SEARCH);
        this.setHeaderState();
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    setHeaderState() {
        const { setHeaderState } = this.props;

        setHeaderState({
            name: BLOG_SEARCH,
        });
    }

    onSearchOutsideClick() {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const { isSearchVisible } = this.state;

        if (!isSearchVisible) {
            return;
        }

        hideActiveOverlay();
        goToPreviousNavigationState();

        this.setState({ isSearchVisible: false });
    }

    containerProps = () => {
        const { searchCriteria, isSearchVisible } = this.state;
        const { mix } = this.props;

        return {
            searchCriteria,
            isSearchVisible,
            mix,
        };
    };

    render() {
        return <BlogSearchBar {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogSearchBarContainer);
