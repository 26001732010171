import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import './CheckoutSuccessSummary.style';

/** @namespace Pwa/Component/CheckoutSuccessSummary/Component */
export class CheckoutSuccessSummary extends PureComponent {
    render() {
        const { subtotal, fee, shippingAndHandling, total, currencyCode } = this.props;

        return (
            <div block="CheckoutSuccessSummary">
                <div block="CheckoutSuccessSummary" elem="Box">
                    <div>
                        <p>{__('Total')}</p>
                        <span>(brutto)</span>
                    </div>
                    <p>{formatPrice(subtotal, currencyCode)}</p>
                </div>
                <div block="CheckoutSuccessSummary" elem="Box">
                    <div>
                        <p>{__('Payment cost')}</p>
                        <span>(brutto)</span>
                    </div>
                    <p>{formatPrice(fee, currencyCode)}</p>
                </div>
                <div block="CheckoutSuccessSummary" elem="Box">
                    <div>
                        <p>{__('Delivery cost')}</p>
                        <span>(brutto)</span>
                    </div>
                    <p>{formatPrice(shippingAndHandling, currencyCode)}</p>
                </div>
                <div block="CheckoutSuccessSummary" elem="Box BoxTotal">
                    <div>
                        <p>{__('Summary')}</p>
                        <span>(brutto)</span>
                    </div>
                    <p>{formatPrice(total, currencyCode)}</p>
                </div>
            </div>
        );
    }
}

export default CheckoutSuccessSummary;
