import {
    CATEGORY_PRODUCT_LIST,
    HOMEPAGE,
    NOT_APPLICABLE,
    PRODUCT_LIST_WIDGET,
    SEARCH,
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { getActionField } from './actionField.data';
import { getBrand, getCategories, getPrice } from './baseProduct.data';

/** @namespace Mirat/GtmNew/EventData/Impression/Data/getAction */
export const getAction = (list = '', store) => {
    switch (list) {
        case CATEGORY_PRODUCT_LIST:
            return store.CategoryReducer.category.name;
        case PRODUCT_LIST_WIDGET:
            return HOMEPAGE;
        case SEARCH:
            return SEARCH;
        default:
            return getActionField(store, window.location.pathname);
    }
};

/** @namespace Mirat/GtmNew/EventData/Impression/Data/getItem */
export const getItem = (item, position, list) => {
    const { sku, name, type_id, price, categories = [] } = item;

    return {
        name,
        id: sku,
        price: getPrice(item, type_id, price),
        category: getCategories(categories),
        brand: getBrand(item),
        position,
        list,
        dimension1: NOT_APPLICABLE,
        dimension2: NOT_APPLICABLE,
    };
};

/** @namespace Mirat/GtmNew/EventData/Impression/Data/getImpressionsData */
export const getImpressionsData = (data = [], store, list = '') => {
    const { products, list: fallbackList = '' } = data;
    const currList = list !== '' ? list : fallbackList;
    const actionList = getAction(currList, store);
    const impressions = [];

    products.map((product, i) => impressions.push(getItem(product, i + 1, actionList)));

    return impressions;
};
