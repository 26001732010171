import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import { DEFAULT_SLICK_SETTINGS } from 'Component/FeaturedBrands/FeaturedBrands.config';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Link from 'Component/Link';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import TypographyHeader from 'Component/TypographyHeader';

import './FeaturedBrands.style';

/** @namespace Pwa/Component/FeaturedBrands/Component */
export class FeaturedBrands extends PureComponent {
    static propTypes = {
        sliderItems: PropTypes.arrayOf(
            PropTypes.shape({
                brand_id: PropTypes.number,
                image: PropTypes.string,
                url_key: PropTypes.string,
                value: PropTypes.string,
            })
        ),
    };

    renderSliderSlides(sliderItems) {
        return sliderItems.map(({ brand_id, image, url_key, value }) => (
            <div block="FeaturedBrands" elem="Brand" key={`slider${brand_id}`}>
                <Link to={url_key}>
                    <ImageLazyLoading
                        className="FeaturedBrands-Img"
                        src={image}
                        alt={value}
                        lazyLoadingType="brandsSlider"
                    />
                </Link>
            </div>
        ));
    }

    render() {
        const { sliderItems } = this.props;

        if (!sliderItems || !sliderItems.length) {
            return null;
        }

        return (
            <div block="FeaturedBrands">
                <TypographyHeader tag="h4" variant="medium" mix={{ block: 'FeaturedBrands', elem: 'Title' }}>
                    {__('Popular brands')}
                </TypographyHeader>
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                >
                    {this.renderSliderSlides(sliderItems)}
                </SlickSlider>
            </div>
        );
    }
}

export default FeaturedBrands;
