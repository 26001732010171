import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

/** @namespace Pwa/Component/AddToCart/Component */
export class AddToCart extends SourceAddToCart {
    render() {
        const { mix, addProductToCart, layout, isDisabled, isAdding } = this.props;

        return (
            <button
                onClick={addProductToCart}
                block="Button AddToCart"
                mix={mix}
                mods={{ layout }}
                disabled={isDisabled || isAdding}
            >
                {this.renderCartIcon()}
                {isAdding ? __('Adding...') : __('Add to cart')}
            </button>
        );
    }
}

export default AddToCart;
