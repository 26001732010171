export default {
    'Mirat/Blog/Route/PostsDetails/Container/PostsDetailsContainer': {
        'member-function': {
            updateBreadcrumbs(args, callback, instance) {
                const { updateBreadcrumbs, post } = instance.props;
                const { title } = post;

                if (!title) {
                    return;
                }

                const breadcrumbs = [
                    {
                        name: title
                    },
                    {
                        url: '/blog',
                        name: __('Blog')
                    },
                ];

                updateBreadcrumbs(breadcrumbs);
            }
        },
    },
};
