import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import PromoBannersQuery from 'Query/PromoBanners.query';
import { fetchMutation } from 'Util/Request';

import PromoBannerAnalytics from './PromoBannerAnalytics.component';

/** @namespace Pwa/Component/PromoBannerAnalytics/Container */
export class PromoBannerAnalyticsContainer extends PureComponent {
    static propTypes = {
        bannerId: PropTypes.number,
    };

    containerFunctions = {
        clickTrackHandle: this.clickTrackHandle.bind(this),
        onEnterViewport: this.onEnterViewport.bind(this),
    };

    state = {
        disableAnalytics: false,
    };

    /**
     * Method not used. Click track is handled via proxy request to Magento2.
     * Important code added in ScandiPWA_ProxyUrl module
     * @returns {Promise<void>}
     */
    async clickTrackHandle() {
        const { bannerId } = this.props;

        await fetchMutation(PromoBannersQuery.setPromoBannerClickMutation(bannerId, window.location.href));
    }

    async onEnterViewport() {
        const { bannerId } = this.props;
        const { disableAnalytics } = this.state;

        if (!disableAnalytics) {
            await fetchMutation(PromoBannersQuery.setPromoBannerImpressionMutation(bannerId, window.location.href));
            this.setState({ disableAnalytics: true });
        }
    }

    render() {
        const { children } = this.props;

        return <PromoBannerAnalytics {...this.containerFunctions}>{children}</PromoBannerAnalytics>;
    }
}

export default withRouter(PromoBannerAnalyticsContainer);
