import PropTypes from 'prop-types';

import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.override.style';

/** @namespace Pwa/Component/Overlay/Component */
export class Overlay extends SourceOverlay {
    static propTypes = {
        ...SourceOverlay.PropTypes,
        showBackground: PropTypes.bool,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...SourceOverlay.defaultProps,
        showBackground: false,
    };

    handleHideOverlay() {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            hideActiveOverlay();
            goToPreviousNavigationState();
        }
    }

    hideOverlay = () => {
        this.handleHideOverlay();
    };

    renderOverlayBackground() {
        return (
            <a block="Overlay" elem="Background" onClick={this.hideOverlay}>
                &nbsp;
            </a>
        );
    }

    render() {
        const { children, mix, areOtherOverlaysOpen, isStatic, showBackground } = this.props;
        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <>
                <div
                    block="Overlay"
                    ref={this.overlayRef}
                    mods={{ isVisible, isStatic, isInstant: areOtherOverlaysOpen }}
                    mix={{ ...mix, mods: { ...mix.mods, isVisible } }}
                >
                    {children && children}
                </div>
                {showBackground && isVisible && this.renderOverlayBackground()}
            </>
        );
    }
}

export default Overlay;
