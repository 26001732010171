import {
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES,
} from 'SourceUtil/Validator/Config.js';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Config.js';

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.phone]: __(
        'Incorrect phone input! Please enter phone number in the correct format, e.g. XXX XXX XXX'
    ),
};

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,6}$/im,
};
