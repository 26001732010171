import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Icons from './Icons.component';
import * as IconsConfig from './Icons.config';

/** @namespace Pwa/Component/Icons/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/Icons/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/Icons/Container */
export class IconsContainer extends PureComponent {
    static propTypes = {
        name: PropTypes.oneOf([
            'magnifier',
            'user',
            'bag',
            'compare',
            'dropdown_arrow',
            'dropdown_arrow_up',
            'close',
            'arrow_left',
            'arrow_right',
            'arrow_up',
            'arrow_down',
            'phone',
            'envelop',
            'clock',
            'youtube',
            'twitter',
            'facebook',
            'pinterest',
            'instagram',
            'eye_crossed',
            'eye',
            'calendar',
            'badge',
            'hand',
            'chat',
            'car',
            'heart',
            'pin',
            'logo_small',
            'trash',
            'plus',
            'time',
            'pencil',
            'truck',
            'envelope',
            'clock_2',
            'truck_2',
            'phone_2',
            'box',
            'angle_right',
            'freeshipping',
            'edit',
            'flatrate',
            'next',
            'check',
            'warning',
        ]).isRequired,
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
    };

    static defaultProps = {
        fill: '#000',
        width: '30',
        height: '30',
    };

    containerProps() {
        const { name, ...rest } = this.props;

        return {
            icon: this._getIconByName(name, rest),
        };
    }

    _getIconByName(name, props) {
        if (name) {
            const nameUpperCase = name.toUpperCase();
            if (typeof IconsConfig[nameUpperCase] === 'function') {
                return IconsConfig[nameUpperCase](props);
            }
        }

        return '';
    }

    render() {
        return <Icons {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
