import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SearchItemContainer as SourceSearchItemContainer,
} from 'SourceComponent/SearchItem/SearchItem.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/SearchItem/Container */
export class SearchItemContainer extends SourceSearchItemContainer {
    handleItemClick(e) {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
        if (e.currentTarget.closest('.MobilePopup') !== null) {
            e.currentTarget.closest('.MobilePopup').children[0].children[1].click();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
