import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Accordion.style';

/** @namespace Pwa/Component/Accordion/Component */
export class Accordion extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        dataDevice: PropTypes.string,
    };

    render() {
        const { dataDevice, children } = this.props;

        return (
            <div block="Accordion" data-device={dataDevice}>
                {children}
            </div>
        );
    }
}

export default Accordion;
