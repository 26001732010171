import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import './Label.style';

/** @namespace Pwa/Component/Label/Component */
export class Label extends PureComponent {
    static propTypes = {
        image: PropTypes.string,
        text: PropTypes.string,
        text_background_color: PropTypes.string,
        text_color: PropTypes.string,
        url: PropTypes.string,
    };

    static defaultProps = {
        image: null,
        text: null,
        text_background_color: '#fff',
        text_color: '#000',
        url: null,
    };

    render() {
        const { image, text, text_background_color, text_color, url, history } = this.props;
        const iconOnly = image && !text;
        const color = text_color || '#000';
        let background = text_background_color || '#fff';

        if (iconOnly) {
            background = null;
        }

        return (
            <div
                block="Label"
                mods={{ iconOnly }}
                style={{ color, background }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (url) {
                        history.push(url);
                    }
                }}
            >
                {image ? <img block="Label" elem="Image" mods={{ iconOnly }} src={image} alt={text} /> : null}
                {text}
            </div>
        );
    }
}

export default withRouter(Label);
