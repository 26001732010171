import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Pwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    getOptions() {
        const { options, attr: { id = 'select', selectPlaceholder = __('Select item...'), noPlaceholder } = {} } =
            this.props;

        if (noPlaceholder) {
            return options;
        }

        return [
            {
                id: `${id}-placeholder`,
                name: `${id}-placeholder`,
                label: selectPlaceholder,
                value: 'placeholder',
                sort_order: -100,
                isPlaceholder: true,
            },
            ...options,
        ];
    }
}

export default FieldSelectContainer;
