import PropTypes from 'prop-types';

import {
    CategoriesType,
    DescriptionType,
    ItemShape,
    MediaType,
    ProductItemsType,
    ProductLinksType,
    ReviewsType,
} from 'SourceType/ProductList.type';
import { MetaTitleType } from 'Type/Common.type';

export * from 'SourceType/ProductList.type';

export const ProductType = PropTypes.shape({
    canonical_url: PropTypes.string,
    categories: CategoriesType,
    description: DescriptionType,
    media_gallery_entries: MediaType,
    meta_description: PropTypes.string,
    meta_keyword: PropTypes.string,
    meta_title: MetaTitleType,
    product_links: ProductLinksType,
    special_price: PropTypes.number,
    url_key: PropTypes.string,
    quantity: PropTypes.number,
    items: ProductItemsType,
    reviews: ReviewsType,
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            image: PropTypes.string,
            text: PropTypes.string,
            text_background_color: PropTypes.string,
            text_color: PropTypes.string,
            color: PropTypes.string,
        })
    ),
    calculated_delivery_time: PropTypes.string,
    calculated_delivery_cost: PropTypes.string,
    alternative_hover_image: PropTypes.string,
    related_products_by_attribute: PropTypes.arrayOf(
        PropTypes.shape({
            url_key: PropTypes.string,
        })
    ),
    ...ItemShape,
});
