import FIELD_TYPE from 'Component/Field/Field.config';
import { getRegionFields } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export { getRegionFields };

/** @namespace Pwa/Component/MyAccountAddressForm/Form/getStreetFields */
export const getStreetFields = (props) => {
    const {
        addressLinesQty = 1,
        address: { street = [] },
    } = props;

    if (addressLinesQty === 1) {
        return [
            {
                type: FIELD_TYPE.text,
                label: __('Street address'),
                attr: {
                    name: 'street',
                    defaultValue: street[0] || '',
                    placeholder: __('Enter your street address...'),
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: true,
                },
            },
        ];
    }

    const streets = [];

    // eslint-disable-next-line fp/no-loops, fp/no-let
    for (let i = 0; i < addressLinesQty; i++) {
        let label = __('Street address line %s', i + 1);
        let placeholder = __('Enter your street address line %s...', i + 1);

        if (i === 0) {
            label = __('Street');
            placeholder = __('Enter your street...');
        }
        if (i === 1) {
            label = __('House / flat number');
            placeholder = __('Enter your house / flat number...');
        }

        streets.push({
            type: FIELD_TYPE.text,
            label,
            attr: {
                name: `street_${i}`,
                defaultValue: street[i] || '',
                placeholder,
            },
            addRequiredTag: i === 0,
            validateOn: i === 0 ? ['onChange'] : [],
            validationRule: {
                isRequired: i === 0,
            },
        });
    }

    return streets;
};

/** @namespace Pwa/Component/MyAccountAddressForm/Form/getVatFields */
export const getVatFields = (props) => {
    const { showVatNumber, vat_id: vatID = '' } = props;

    if (!showVatNumber) {
        return [];
    }

    return [
        {
            type: FIELD_TYPE.text,
            label: __('VAT Number'),
            attr: {
                placeholder: __('___ ___ __ __'),
                name: 'vat_id',
                defaultValue: vatID,
            },
            addRequiredTag: false,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: false,
            },
        },
    ];
};

/** @namespace Pwa/Component/MyAccountAddressForm/Form/myAccountAddressForm */
export const myAccountAddressForm = (props, events = {}, showInfo = false) => {
    const {
        default_billing: defaultBilling,
        default_shipping: defaultShipping,
        countryId,
        firstname = '',
        lastname = '',
        city = '',
        countries = [],
        postcode = '',
        telephone = '',
        addressLinesQty = 1,
        company = '',
    } = props;

    const { onCountryChange, onZipcodeBlur } = events || {};

    return [
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Billing Address'),
            attr: {
                name: 'default_billing',
                defaultChecked: defaultBilling,
            },
        },
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Shipping Address'),
            attr: {
                name: 'default_shipping',
                defaultChecked: defaultShipping,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('First name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname,
                placeholder: __('Enter your first name...'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last name'),
            attr: {
                name: 'lastname',
                defaultValue: lastname,
                placeholder: __('Enter your last name...'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Company'),
            attr: {
                name: 'company',
                defaultValue: company,
                placeholder: __('Company name'),
            },
        },
        ...getVatFields(props),
        {
            component: () => (
                <p block="MyAccountAddressForm" elem="ExtraInfo">
                    {__('Invoice data other than for delivery will be available on the next page')}
                </p>
            ),
            visible: showInfo,
        },
        {
            name: 'streetGroup',
            mods: {
                street: true,
                multipleFields: addressLinesQty > 0,
                oddAddresses: addressLinesQty % 2 === 1,
            },
            fields: getStreetFields(props),
        },
        {
            type: FIELD_TYPE.text,
            label: __('City'),
            attr: {
                name: 'city',
                defaultValue: city,
                placeholder: __('Enter your city...'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
        },
        {
            type: FIELD_TYPE.text,
            label: __('Zip / Postal code'),
            attr: {
                name: 'postcode',
                defaultValue: postcode,
                placeholder: __('Enter your zip / postal code...'),
            },
            events: {
                onBlur: onZipcodeBlur,
            },
            addRequiredTag: true,
            validateOn: ['onChange', 'onBlur'],
            validationRule: {
                isRequired: true,
                validateZipFormat: {
                    countryId,
                },
            },
        },
        {
            type: FIELD_TYPE.select,
            label: __('Country'),
            attr: {
                id: 'address-country-id',
                name: 'country_id',
                defaultValue: countryId,
                selectPlaceholder: __('Select country...'),
            },
            events: {
                onChange: onCountryChange,
            },
            options: countries,
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
            },
            variant: 'secondary',
            size: 'small',
        },
        ...getRegionFields(props, events),
        {
            type: FIELD_TYPE.tel,
            label: __('Phone number'),
            attr: {
                name: 'telephone',
                defaultValue: telephone,
                placeholder: __('Enter your phone number...'),
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.phone,
                isRequired: true,
            },
        },
        {
            component: () => (
                <p block="MyAccountAddressForm" elem="ExtraInfo">
                    {__('The telephone number will only be used in matters relating to the order')}
                </p>
            ),
            visible: showInfo,
        },
    ];
};

export default myAccountAddressForm;
