import Icons from 'Component/Icons';
import ClickOutside from 'SourceComponent/ClickOutside';
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Pwa/Component/FieldSelect/Component */
export class FieldSelect extends SourceFieldSelect {
    renderOption(option) {
        const { id, label, subLabel, isPlaceholder = false, isHovered, value } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr: { value: selectedValue },
        } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{ isExpanded, isPlaceholder, isHovered, isSelected: selectedValue === value }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onMouseDown={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onTouchStart={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderNativeSelect() {
        const { setRef, attr, events, isDisabled, options, isExpanded, handleSelectListOptionClick } = this.props;

        return (
            <select
                block="FieldSelect"
                elem="Select"
                mods={{ isExpanded, isDisabled }}
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...attr}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...events}
                onChange={handleSelectListOptionClick}
            >
                {options.map(this.renderNativeOption.bind(this))}
            </select>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            isDisabled,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded }}
                    onClick={!isDisabled ? handleSelectExpand : null}
                    onKeyPress={handleSelectListKeyPress}
                    role="button"
                    tabIndex="0"
                    aria-label="Select dropdown"
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderNativeSelect()}
                        <div block="FieldSelect" elem="Arrow" mods={{ isExpanded, isDisabled }}>
                            <Icons name="dropdown_arrow" width="20" />
                        </div>
                    </div>
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
