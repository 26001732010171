import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import loader from '../../assets/loader/loader.svg';

import './Loader.override.style';

/** @namespace Pwa/Component/Loader/Component */
export class Loader extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        height: PropTypes.number,
        displayRelative: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        height: null,
        displayRelative: false,
    };

    render() {
        const { isLoading, displayRelative, height } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mods={{ isRelative: displayRelative }} style={height ? { height } : {}}>
                <div block="Loader" elem="Scale">
                    <img src={loader} alt="loader" />
                </div>
            </div>
        );
    }
}

export default Loader;
