import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { ProductCustomizableOption as SourceProductCustomizableOption } from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.component';

/** @namespace Pwa/Component/ProductCustomizableOption/Component */
export class ProductCustomizableOption extends SourceProductCustomizableOption {
    renderSelectValues() {
        const { getDropdownOptions, updateSelectedValues, isRequired, uid } = this.props;

        return (
            <div block="ProductCustomizableItem" elem="DropdownWrapper">
                <Field
                    type={FIELD_TYPE.select}
                    attr={{
                        id: `customizable-options-dropdown-${uid}`,
                        name: `customizable-options-dropdown-${uid}`,
                        selectPlaceholder: __('Select option...'),
                    }}
                    mix={{ block: 'ProductCustomizableItem', elem: 'Select' }}
                    options={getDropdownOptions()}
                    events={{
                        onChange: updateSelectedValues,
                    }}
                    validationRule={{
                        isRequired,
                    }}
                    validateOn={['onChange']}
                    variant="teriary"
                />
            </div>
        );
    }

    renderOptionGroupTitle(title) {
        const { isRequired } = this.props;

        return (
            <div block="ProductCustomizableItem" elem="Heading">
                {title}
                {isRequired && (
                    <strong block="ProductCustomizableItem" elem="Required">
                        *
                    </strong>
                )}
            </div>
        );
    }
}

export default ProductCustomizableOption;
