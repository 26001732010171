import PropTypes from 'prop-types';

import { FieldGroup as SourceFieldGroup } from 'SourceComponent/FieldGroup/FieldGroup.component';

/** @namespace Pwa/Component/FieldGroup/Component */
export class FieldGroup extends SourceFieldGroup {
    static propTypes = {
        ...SourceFieldGroup.propTypes,
        validationResponse: PropTypes.oneOfType([
            PropTypes.shape({ errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]) }),
            PropTypes.bool,
        ]),
    };

    renderError(message, key) {
        return (
            <div block="Field" elem="ErrorMessage" key={key}>
                {message}
            </div>
        );
    }

    renderErrorMessages() {
        const { showErrorAsLabel, validationResponse, name } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                {errorMessages.map((message, index) => this.renderError.call(this, message, name + index))}
            </div>
        );
    }
}

export default FieldGroup;
