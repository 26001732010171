import PropTypes from 'prop-types';

import ImageLazyLoading from 'Component/ImageLazyLoading';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

/** @namespace Pwa/Component/Image/Component */
export class Image extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        useNativeLazyLoading: PropTypes.bool,
        lazyLoadingType: PropTypes.string,
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        useNativeLazyLoading: false,
        lazyLoadingType: null,
    };

    renderStyledImage() {
        const { alt, src, style, title, useNativeLazyLoading, lazyLoadingType } = this.props;

        return useNativeLazyLoading ? (
            super.renderStyledImage()
        ) : (
            <ImageLazyLoading
                className="Image-Image"
                src={src || ''}
                alt={alt}
                style={style}
                title={title}
                lazyLoadingType={lazyLoadingType}
            />
        );
    }

    renderImageNotFound() {
        return null;
    }
}

export default Image;
