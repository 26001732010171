import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/Icons/Component */
export class IconsComponent extends PureComponent {
    static propTypes = {
        icon: PropTypes.element.isRequired,
    };

    render() {
        const { icon } = this.props;

        return icon;
    }
}

export default IconsComponent;
