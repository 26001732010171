import { getPostUrl } from '@mirat/blog/src/util/Posts';

import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { convertBlogDateFormat } from '../util/BlogDate';

import '../component/BlogPostCard/BlogPostCard.style.scss';

export default {
    'Mirat/Blog/Component/BlogPostCard/Component/BlogPostCardComponent': {
        'member-function': {
            renderImage(args, callback, instance) {
                const {
                    post: { first_image, title },
                    post,
                } = instance.props;

                if (!first_image) {
                    return null;
                }

                return (
                    <Link
                        to={getPostUrl(post)}
                        title={__('View %s', title)}
                        mix={{ block: 'BlogPostCart', elem: 'ImageWrapper' }}
                    >
                        <Image mix={{ block: 'BlogPostCart', elem: 'Image' }} ratio="square" src={first_image} />
                    </Link>
                );
            },
            renderTags(args, callback, instance) {
                const {
                    post: { tags },
                    isLoaded,
                } = instance.props;

                if (isLoaded && !tags) {
                    return null;
                }

                return tags.map((tag) => (
                    <div key={ tag.title } mix={ { block: 'BlogPostCart', elem: 'Tag' } }>
                        { tag.title }
                    </div>
                ));
            },

            renderTitle(args, callback, instance) {
                const {
                    post: { title },
                    post,
                    isRelated,
                } = instance.props;

                if (!title) {
                    return null;
                }

                return (
                    <Link to={getPostUrl(post)}>
                        <div block="BlogPostCart" elem="Title" mods={{ isRelated }}>
                            {title}
                        </div>
                    </Link>
                );
            },
            renderDate(args, callback, instance) {
                const {
                    post: { publish_time },
                } = instance.props;

                if (!publish_time) {
                    return null;
                }

                return (
                    <div mix={{ block: 'BlogPostCart', elem: 'Date' }}>
                        <TextPlaceholder
                            mix={{ block: 'BlogPostCart', elem: 'Date' }}
                            content={convertBlogDateFormat(publish_time)}
                        />
                    </div>
                );
            },

            renderContents(args, callback, instance) {
                const { isPlaceholder, isRelated } = instance.props;

                if (isPlaceholder) {
                    return null;
                }

                return (
                    <div block="BlogPostCart" elem="ContentWrapper" mods={ { expand: true, isRelated } }>
                        <div block="BlogPostCart" elem="ImageBox">
                            { instance.renderImage() }
                            <div block="BlogPostCart" elem="TagsBox">
                                { instance.renderTags() }
                            </div>
                        </div>
                        <div block="BlogPostCart" elem="Details" mods={ { isRelated } }>
                            { instance.renderTitle() }
                            <div block="BlogPostCart" elem="DateWrapper">
                                <Icons name="calendar" width="22" height="22" />

                                { instance.renderDate() }
                            </div>
                            <div block="BlogPostCart" elem="Description">
                                {instance.renderDescription()}
                            </div>
                        </div>
                    </div>
                );
            },
            render(args, callback, instance) {
                const { isPlaceholder, isCompactOnMobile } = instance.props;
                return (
                    <div block="BlogPostCart" mods={ { isLoading: isPlaceholder, isCompactOnMobile } }>
                        { instance.renderContents() }
                    </div>
                );
            }
        }
    }
};
