import Icons from 'Component/Icons/Icons.container';
import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.style';

/** @namespace Pwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {
    renderButton() {
        const { isDisabled, isInWishlist } = this.props;

        return (
            <button
                block="ProductWishlistButton"
                elem="Button"
                mods={{ isInWishlist, isDisabled }}
                title={this.getTitle()}
                onClick={this.onClick}
            >
                <Icons name="heart" width="20" height="20" />
            </button>
        );
    }

    renderContent() {
        const { mix, isInWishlist } = this.props;

        return (
            <div block="ProductWishlistButton" mix={{ mix }} mods={{ isInWishlist }}>
                {this.renderButton()}
                {this.renderLoader()}
            </div>
        );
    }
}

export default ProductWishlistButton;
