import PropTypes from 'prop-types';

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import { ProductReviewRating as SourceProductReviewRating } from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

/** @namespace Pwa/Component/ProductReviewRating/Component */
export class ProductReviewRating extends SourceProductReviewRating {
    static propTypes = {
        ...SourceProductReviewRating.propTypes,
        showCounter: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'normal', 'large']),
    };

    static defaultProps = {
        ...SourceProductReviewRating.defaultProps,
        showCounter: true,
        size: 'small',
    };

    render() {
        const { summary, code, placeholder, mix, count, showCounter, size } = this.props;
        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();
        const ariaText = this.getAriaText(summary, code);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div
                block="ProductReviewRating"
                title={`${summary}%`}
                ref={this.reviewRating}
                aria-label={ariaText}
                mix={mix}
                mods={{ size }}
            >
                {this.renderStar(fullCount, STAR_FULL)}
                {this.renderStar(halfFullCount, STAR_HALF_FULL)}
                {this.renderStar(emptyCount, STAR_EMPTY)}

                {showCounter && (
                    <span block="ProductReviewRating" elem="Counter">
                        {count} {count === 1 ? __('opinion') : __('opinions')}
                    </span>
                )}
            </div>
        );
    }
}

export default ProductReviewRating;
