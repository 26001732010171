import Button from 'Component/Button';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import { CheckoutAddressBook as SourceCheckoutAddressBook } from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import { getDefaultAddressLabel } from 'Util/Address';

import './CheckoutAddressBook.style';

/** @namespace Pwa/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBook extends SourceCheckoutAddressBook {
    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;

        return (
            <div block="CheckoutAddressBook" elem="CustomAddressWrapper">
                {!isCustomAddressExpanded && (
                    <Button variant="outlineInverse" onClick={this.expandCustomAddress}>
                        {__('Use other address')}
                    </Button>
                )}
                {isCustomAddressExpanded && this.renderCustomAddress()}
            </div>
        );
    }

    renderAddress(address, index) {
        const { onAddressSelect, selectedAddressId, isBilling } = this.props;
        const addressNumber = index + 1;
        const { id, default_billing, default_shipping } = address;
        const postfix =
            (isBilling && default_billing) || (!isBilling && default_shipping) ? getDefaultAddressLabel(address) : '';

        return (
            <CheckoutAddressTable
                onClick={onAddressSelect}
                isSelected={selectedAddressId === id}
                title={__('Address #%s%s', addressNumber, postfix)}
                address={address}
                key={id}
                showAdditionalFields
            />
        );
    }

    render() {
        return <div block="CheckoutAddressBook">{this.renderContent()}</div>;
    }
}

export default CheckoutAddressBook;
