import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getPlaceOrderMutation(guestCartId, customerComment, newsletterSubscription) {
        const mutation = new Field('s_placeOrder')
            .setAlias('placeOrder')
            .addArgument('customerComment', 'String', customerComment)
            .addArgument('subscribeToNewsletter', 'Int', +newsletterSubscription)
            .addField(this._getOrderData());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    _getOrderData() {
        return new Field('order').addFieldList([
            'items',
            'order_number',
            'total',
            'subtotal',
            'shipping_and_handling',
            'tax',
            'fee',
            'url',
            'ca_raty_form',
            this._getBillingAddress(),
            this._getPaymentInfo(),
            this._getOrderProducts(),
            this._getShippingInfo(),
            this._getBaseOrderInfo(),
        ]);
    }

    _getPaymentInfo() {
        return new Field('payment_info').addFieldList(['method', this._getPaymentAdditionalInfo()]);
    }

    _getPaymentAdditionalInfo() {
        return new Field('additional_information').addField('instructions');
    }

    _getOrderProducts() {
        return new Field('order_products').addFieldList([
            'calculated_delivery_time',
            this._getProductImage(),
            'name',
            'original_price',
            'quantity',
            'qty',
            'special_price',
            'unit_price',
            'row_total',
        ]);
    }

    _getBaseOrderInfo() {
        return new Field('base_order_info').addFieldList(['currency_code']);
    }

    _getProductImage() {
        return new Field('image').addField('url');
    }

    _getShippingInfo() {
        return new Field('shipping_info').addFieldList([
            this._getShippingAddress(),
            'shipping_amount',
            'shipping_description',
            'shipping_incl_tax',
            'shipping_method',
            'tracking_numbers',
        ]);
    }

    _getShippingAddress() {
        return new Field('shipping_address').addFieldList([
            'apartment_number',
            'city',
            'company',
            'country_id',
            'customer_id',
            'district',
            'firstname',
            'house_number',
            'id',
            'is_b2b',
            'lastname',
            'middlename',
            'organizationaddress',
            'organizationbik',
            'organizationbin',
            'organizationiic',
            'organizationname',
            'post_office_code',
            'postcode',
            'postomat_code',
            'prefix',
            'region',
            'store_pickup_code',
            'street',
            'telephone',
            'vat_id',
        ]);
    }

    _getBillingAddress() {
        return new Field('billing_address').addFieldList([
            'firstname',
            'lastname',
            'middlename',
            'region',
            'region_id',
            'country_code',
            'street',
            'company',
            'telephone',
            'fax',
            'postcode',
            'city',
            'prefix',
            'suffix',
            'vat_id',
        ]);
    }
}

export default new CheckoutQuery();
