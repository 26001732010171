import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './ProductCardBanner.style';

/** @namespace Pwa/Component/ProductCardBanner/Component */
export class ProductCardBanner extends PureComponent {
    static propTypes = {
        banner: PropTypes.string.isRequired,
        layout: PropTypes.string.isRequired,
    };

    render() {
        const { banner, layout } = this.props;

        return (
            <li block="ProductCardBanner" mix={{ block: 'Banner', mods: { layout } }}>
                <Html content={banner} />
            </li>
        );
    }
}

export default ProductCardBanner;
