import BlogSearchOverlay from '@mirat/blog/src/component/BlogSearchOverlay';
import React from 'react';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import Icons from 'Component/Icons';

import '../component/BlogSearchBar/BlogSearchBar.style.scss';

export default {
    'Mirat/Blog/Component/BlogSearchBar/Component/BlogSearchBarComponent': {
        'member-function': {
            renderCloseButton(args, callback, instance) {
                const { isSearchVisible, onCloseButtonClick } = instance.props;

                if (!isSearchVisible) {
                    return;
                }

                return (
                    <button
                      key="close"
                      block="BlogSearchBar"
                      elem="Button"
                      mods={ { type: 'close' } }
                      onClick={ onCloseButtonClick }
                      aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                );
            },
            renderSearchButton(args, callback, instance) {
                const { isSearchVisible } = instance.props;
                if (isSearchVisible) {
                    return;
                }

                return (
                    <button
                      key="blogsearch"
                      block="BlogSearchBar"
                      elem="Button"
                      mods={ { type: 'blogsearch' } }
                      onClick={ () => {} }
                      aria-label="Search"
                    >
                        <div block="BlogSearchBar" elem="ActionIcon">
                            <Icons name="magnifier" width="22" height="22" />
                        </div>
                    </button>
                );
            },
            render(args, callback, instance) {
                const {
                    mix,
                    searchCriteria,
                    isSearchVisible,
                    onSearchBarClick,
                    onSearchBarChange,
                    onSearchOutsideClick,
                } = instance.props;

                return (
                    <div mix={mix}>
                        <ClickOutside onClick={onSearchOutsideClick}>
                            <div block="BlogSearchBar" elem="SearchWrapper" aria-label="Search">
                                <div block="BlogSearchBar" elem="Search" mods={{ isActive: isSearchVisible } }>
                                    <input
                                        id="posts-search-field"
                                        placeholder={ __('Search...') }
                                        block="BlogSearchBar"
                                        elem="SearchInput"
                                        onClick={onSearchBarClick}
                                        onChange={onSearchBarChange}
                                        value={searchCriteria}
                                        mods={{ type: 'searchField' }}
                                    />
                                    { instance.renderSearchButton() }
                                    { instance.renderCloseButton() }
                                </div>
                                <BlogSearchOverlay
                                    searchCriteria={searchCriteria}
                                    onSearchOutsideClick={onSearchOutsideClick}
                                    isSearchVisible={isSearchVisible}
                                />
                            </div>
                        </ClickOutside>
                    </div>
                );
            },
        },
    },
};
